// Import vendors ----------------------------------------------------------------------------------
import { inject } from '@vue/composition-api';
import { provide } from '@/plugins/composition-api';
import { useService } from '@xstate/vue';
// Import IoC --------------------------------------------------------------------------------------
import { container } from './container';
// eslint-disable-next-line import/no-unassigned-import
import './binds';
import { TOKENS } from './tokens';
// Import core -------------------------------------------------------------------------------------
import { Podocore } from './Podocore';
// Import modules ----------------------------------------------------------------------------------
import { Modules } from './modules';
// Import repositories -----------------------------------------------------------------------------
import { Repositories } from './repositories';
// -------------------------------------------------------------------------------------------------

export { Podocore } from './Podocore';

// Shortcuts
export { container, TOKENS }; // Do not use shortcut export with lazyInject, call files instead

/**
 * Initialize Podocore
 */
export function setup(): void {
  createPodocore();
}

export function createPodocore(): void {
  provide<Podocore>('podocore', container.get<Podocore>(TOKENS.Podocore));
}

/**
 * Use Podocore instance
 */
export function usePodocore(): Podocore {
  const core = inject<Podocore>('podocore');
  if (!core) throw new Error('Core not loaded');
  return core;
}

/**
 * Use Podocore module
 */
export function usePodocoreModule<M extends keyof Modules>(moduleId: M): Modules[M] {
  return usePodocore().getModule(moduleId);
}

/**
 * Use Podocore module service
 */
export function usePodocoreModuleService<M extends keyof Modules>(moduleId: M): ReturnType<typeof useService>;
export function usePodocoreModuleService<M extends keyof Modules>(
  moduleId: M,
  allowUndefined: boolean
): ReturnType<typeof useService> | undefined;
export function usePodocoreModuleService<M extends keyof Modules>(
  moduleId: M,
  allowUndefined?: boolean
): ReturnType<typeof useService> | undefined {
  const service = usePodocore().getModuleService(moduleId);
  if (!service && !allowUndefined) throw new Error(`The required service "${moduleId}" is not accessible`);
  return allowUndefined ? undefined : useService(service);
}

/**
 * Use Podocore repository
 */
export function usePodocoreRepository<R extends keyof Repositories>(repositoryId: R): Repositories[R] {
  return usePodocore().getRepository(repositoryId);
}
