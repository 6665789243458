// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
import moment from 'moment';
import { map } from 'rxjs/operators';
// Import factories --------------------------------------------------------------------------------
import { RepositoryFactory } from '../factories/Repository.factory';
import { SupportedLang } from '@/utils/i18n.utils';
// -------------------------------------------------------------------------------------------------

export type ConfigLang = Record<'sandbox' | 'alpha' | 'beta' | 'production', SupportedLang[]>;

export interface Config {
  lang: ConfigLang;
}

// -------------------------------------------------------------------------------------------------

/**
 * Config repository
 */
@injectable()
export class ConfigRepository extends RepositoryFactory {
  constructor() {
    super();

    this.fetchConfig = this.fetchConfig.bind(this);
  }

  /**
   * Fetch configs
   */
  async fetchConfig(): Promise<Config[]> {
    return this._requestModule
      .request<Config[]>('https://s3.eu-central-1.amazonaws.com/cdn.podosmart.tech/fe-config.json', {
        params: {
          timestamp: moment().unix()
        }
      })
      .pipe(map((response): Config[] => response.data))
      .toPromise();
  }
}
