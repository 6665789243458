// Import types ------------------------------------------------------------------------------------
import type { RawRuleOf } from '@casl/ability';
import type { AppAbility } from './ability';
// -------------------------------------------------------------------------------------------------

/**
 * Extend raw ability
 * @param rawAbility
 */
export function extendRawAbility(rawAbility: RawRuleOf<AppAbility>[]): RawRuleOf<AppAbility>[] {
  //
  // Extend ability here
  //
  return rawAbility;
}
