// Import vendors ----------------------------------------------------------------------------------
import { inject, injectable } from 'inversify';
import { ref, readonly } from '@vue/composition-api';
import { defer, from, of } from 'rxjs';
import { delay, finalize, map, switchMap } from 'rxjs/operators';
import axios from 'axios';
import { compact, merge } from 'lodash';
import { assign, createMachine } from 'xstate';
import { stringify } from 'qs';
// Import factories --------------------------------------------------------------------------------
import { ModuleFactory } from '../../factories/Module.factory';
// Import IoC --------------------------------------------------------------------------------------
import { TOKENS } from '../../tokens';
// Import repositories -----------------------------------------------------------------------------
import { Repositories } from '../../repositories';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
import { versionConfig } from '@/config/version.config';
// Import utils ------------------------------------------------------------------------------------
import { useVersion } from '@/utils/version.utils';
// Import types ------------------------------------------------------------------------------------
import type { Ref } from '@vue/composition-api';
import type { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import type { Observable } from 'rxjs';
// -------------------------------------------------------------------------------------------------

/**
 * Request module
 */
@injectable()
export class RequestModule extends ModuleFactory {
  /** Own Axios instance */
  private readonly _axios;

  constructor(@inject(TOKENS.Repositories) private readonly _repositories: Repositories) {
    super();

    this._axios = axios.create({
      paramsSerializer(parameters) {
        return stringify(parameters);
      }
    });
  }

  /**
   * Request
   */
  public request<T = any>(url: string, config?: AxiosRequestConfig): Observable<AxiosResponse<T>> {
    const stage = process.env.BLACKBURN__STAGE;
    const { currentVersion } = useVersion();

    const version = compact([
      currentVersion.value,
      stage && stage !== 'production' ? stage : undefined,
      versionConfig.default
    ]).join('-');

    return this._getCurrentWorkspace().pipe(
      switchMap((currentWorkspace) => {
        return from(
          this._axios(
            url,
            merge({}, config, {
              headers: merge(
                config?.headers,
                // To avoid CORS issue with SAAS
                url.startsWith(apiConfig.default)
                  ? merge(
                      {
                        'x-client-id': `com.digitsole.digitsolepro.${stage}.web`,
                        'x-client-version': version
                      },
                      currentWorkspace
                        ? {
                            'X-WorkspaceCuid': currentWorkspace.cuid
                          }
                        : null
                    )
                  : null
              )
            })
          )
        );
      })
    );
  }

  /**
   * Authenticated request
   */
  public authenticatedRequest<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Observable<AxiosResponse<T>> {
    return this._core
      .getModule('auth')
      .getUserAccessToken()
      .pipe(
        switchMap((token) =>
          this.request<T>(
            url,
            merge({}, config, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
          )
        )
      );
  }

  /**
   * Request (composition)
   */
  public useRequest<T = any>(url: string, defaultOptions?: { axios?: AxiosRequestConfig; auth?: boolean }) {
    const response = ref(null) as Ref<AxiosResponse<T> | null>;
    const data = ref(null) as Ref<T | null>;
    const isPending = ref<boolean>(false);
    const isCanceled = ref<boolean>(false);
    const error = ref(null) as Ref<Error | AxiosError<T> | null>;

    // eslint-disable-next-line import/no-named-as-default-member
    let cancelToken = axios.CancelToken.source();

    function cancel(message?: string) {
      cancelToken.cancel(message);
      isCanceled.value = true;
      // Define a new token
      cancelToken = axios.CancelToken.source();
    }

    const request = (options?: { axios?: AxiosRequestConfig; auth?: boolean }) => {
      if (isPending.value) return;

      isCanceled.value = false;

      defer(() => {
        isPending.value = true;

        return (options?.auth ?? defaultOptions?.auth ? this.authenticatedRequest : this.request).bind(
          this
        )<T>(
          options?.axios?.url ?? url,
          merge({}, options?.axios ?? defaultOptions?.axios, {
            cancelToken: cancelToken.token
          })
        );
      })
        .pipe(
          finalize(() => {
            isPending.value = false;
          })
        )
        .subscribe({
          error(error_) {
            error.value = error_;
          },
          next(r) {
            response.value = r;
            data.value = r.data;
            error.value = null;
          }
        });
    };

    function clear() {
      response.value = null;
      data.value = null;
      error.value = null;
    }

    return {
      response: readonly(response),
      data: readonly(data),
      error: readonly(error),
      isPending: readonly(isPending),
      isCanceled: readonly(isCanceled),

      request,
      clear,
      cancel
    };
  }

  /**
   * Authenticated request (composition)
   */
  public useAuthenticatedRequest<T = any>(url: string, options?: { axios?: AxiosRequestConfig }) {
    return this.useRequest<T>(url, merge({}, options, { auth: true }));
  }

  /**
   * Use repository (composition)
   */
  public useRepository<T>(
    request: (options?: AxiosRequestConfig) => Observable<AxiosResponse<T>>,
    options?: { immediate?: boolean }
  ) {
    // Reactive properties
    const response = ref(null) as Ref<AxiosResponse<T> | null>;
    const data = ref(undefined) as Ref<T | undefined>;
    const isPending = ref<boolean>(false);
    const isCanceled = ref<boolean>(false);
    const error = ref(null) as Ref<Error | AxiosError<T> | null>;

    // eslint-disable-next-line import/no-named-as-default-member
    const cancelToken = axios.CancelToken.source();

    function cancel(message?: string) {
      cancelToken.cancel(message);
      isCanceled.value = true;
    }

    function clear() {
      data.value = undefined;
    }

    const run = (axios?: AxiosRequestConfig) => {
      if (isPending.value) return;

      defer(() => {
        isPending.value = true;
        return request(merge({}, axios, { cancelToken: cancelToken.token }));
      })
        .pipe(
          finalize(() => {
            isPending.value = false;
          })
        )
        .subscribe({
          error(error_) {
            error.value = error_;
          },
          next(r) {
            response.value = r;
            data.value = r.data;
            error.value = null;
          }
        });
    };

    if (options?.immediate) {
      run();
    }

    return {
      response: readonly(response),
      data: readonly(data),
      error: readonly(error),
      isPending: readonly(isPending),
      isCanceled: readonly(isCanceled),

      run,
      clear,
      cancel
    };
  }

  public statedRequest<T>(request: Observable<AxiosResponse<T>>, initial: 'idle' | 'fetching' = 'fetching') {
    return createMachine({
      context: {
        result: undefined,
        error: undefined
      },
      initial,
      states: {
        idle: {
          on: {
            FETCH: 'fetching'
          }
        },
        fetching: {
          invoke: {
            src: async () => request.toPromise(),
            onDone: {
              actions: assign({
                result(_, { data }) {
                  return data;
                }
              }),
              target: 'success'
            },
            onError: {
              actions: assign({
                error(_, { data }) {
                  return data;
                }
              }),
              target: 'failure'
            }
          }
        },
        success: {
          exit: assign({
            result(_) {
              return undefined;
            }
          }),
          on: {
            FETCH: 'fetching'
          }
        },
        error: {
          exit: assign({
            error(_) {
              return undefined;
            }
          }),
          on: {
            FETCH: 'fetching'
          }
        }
      }
    });
  }

  public getRepository<M extends keyof Repositories>(repositoryName: M): Repositories[M] {
    return this._repositories[repositoryName];
  }

  private _getCurrentWorkspace() {
    return of({}).pipe(
      // HACK : used to counter IoC failure
      delay(1),
      switchMap(() =>
        of(this._core).pipe(
          map((core) => {
            if (core.getModuleService('workspaces', true).state.value === 'enabled') {
              return core.getModule('workspaces').getCurrentWorkspace();
            } else {
              return;
            }
          })
        )
      )
    );
  }
}
