// Import vendors ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
import { ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

/**
 * Use a dialog
 */
export function useDialog() {
  const dialog = ref(false);
  const displayContent = ref(false);

  const busModule = usePodocoreModule('bus');

  /** An event is emitted if the dialogId is provided */
  function open(dialogId?: DialogId) {
    dialog.value = true;

    if (dialogId) busModule.publish(busModule.events.dialogOpened({ id: dialogId }));
  }

  /** An event is emitted if the dialogId is provided */
  function close(dialogId?: DialogId) {
    dialog.value = false;

    if (dialogId) busModule.publish(busModule.events.dialogClosed({ id: dialogId }));
  }

  watch(dialog, (value) => {
    if (value) {
      displayContent.value = true;
    } else {
      setTimeout(() => {
        displayContent.value = false;
      }, 200);
    }
  });

  return {
    dialog,
    displayContent,
    open,
    close
  };
}
