// Import vendors ----------------------------------------------------------------------------------
import { inject, injectable } from 'inversify';
import { actions, assign, sendParent } from 'xstate';
// Import IoC --------------------------------------------------------------------------------------
import { TOKENS } from '../../tokens';
// Import factories --------------------------------------------------------------------------------
import { ModuleWithStateFactory } from '../../factories/ModuleWithState.factory';
// Import helpers ----------------------------------------------------------------------------------
import { createModuleStateMachine } from '../../helpers/modules.helpers';
// Import repositories -----------------------------------------------------------------------------
import { ConfigRepository } from '../../repositories/config.repository';
// Import types ------------------------------------------------------------------------------------
import type { Config } from '../../repositories/config.repository';
// -------------------------------------------------------------------------------------------------

/**
 * Config module
 */
@injectable()
export class ConfigModule extends ModuleWithStateFactory {
  constructor(
    @inject(TOKENS.ConfigRepository)
    public readonly configRepository: ConfigRepository
  ) {
    super();

    this._machine = createModuleStateMachine(
      this._name,
      {
        context: {
          config: undefined
        },
        initial: 'fetching',
        states: {
          fetching: {
            invoke: {
              src: 'fetchConfig',
              onDone: {
                // TODO : Add type
                actions: assign({
                  config(_, event) {
                    return event.data;
                  }
                }),
                target: 'success'
              },
              onError: {
                actions: assign({
                  error(_, event) {
                    return event.data;
                  }
                }),
                target: 'failure'
              }
            }
          },
          success: {
            entry: [
              function () {
                console.log('🗒️ Config fetched');
              },
              actions.pure(() => sendParent({ type: 'FETCHED' }))
            ],
            on: {
              CHECK: 'fetching'
            }
          },
          failure: {
            entry: actions.pure((context) => {
              return sendParent({ type: 'FATAL', data: context.error });
            }),
            exit: assign({
              error(_) {
                return undefined;
              }
            })
          }
        }
      },
      {
        services: {
          fetchConfig: this.configRepository.fetchConfig
        }
      }
    );
  }
}
