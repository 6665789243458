// Import vendors ----------------------------------------------------------------------------------
import Vue from 'vue';
import { computed } from '@vue/composition-api';
import { pick } from 'lodash';
// Import plugins ----------------------------------------------------------------------------------
import router from '@/plugins/router';
// Import types ------------------------------------------------------------------------------------
import type { ComputedRef } from '@vue/composition-api';
import type { RouteMeta, RouteRecord } from 'vue-router';
// Export / declare types --------------------------------------------------------------------------
export type CampaignQueryParameters = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
};
// -------------------------------------------------------------------------------------------------

const matched: RouteRecord[] = [];
const name = '';
const meta: RouteMeta = {};
const parameters: Record<string, unknown> = {};
const query: Record<string, unknown> = {};

const routeData = Vue.observable({
  matched,
  name,
  meta,
  params: parameters,
  query,
  isReady: false,
  hash: undefined as string | undefined
});

router.afterEach((route) => {
  routeData.matched = route.matched;
  routeData.name = route.name!;
  routeData.meta = (route.meta ?? {}) as RouteMeta;
  routeData.params = route.params;
  routeData.query = route.query;
  routeData.hash = route.hash;

  router.onReady(() => {
    console.log('🔀 Router is ready');
    routeData.isReady = true;
  });
});

export function useRoute(): {
  matched: ComputedRef<RouteRecord[]>;
  name: ComputedRef<string>;
  meta: ComputedRef<RouteMeta>;
  query: ComputedRef<Record<string, unknown>>;
  params: ComputedRef<Record<string, unknown>>;
  isReady: ComputedRef<boolean>;
  campaignQuery: ComputedRef<CampaignQueryParameters>;
  hash: ComputedRef<string | undefined>;
} {
  return {
    matched: computed(() => routeData.matched),
    name: computed(() => routeData.name),
    meta: computed(() => routeData.meta),
    params: computed(() => routeData.params),
    query: computed(() => routeData.query),
    isReady: computed(() => routeData.isReady),
    hash: computed(() => routeData.hash),
    campaignQuery: computed(() =>
      pick<CampaignQueryParameters>(routeData.query, [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content'
      ] as Array<keyof CampaignQueryParameters>)
    )
  };
}
