// Import vendors ----------------------------------------------------------------------------------
import { injectable, inject } from 'inversify';
// Import IoC --------------------------------------------------------------------------------------
import { TOKENS } from '../tokens';
// Import modules ----------------------------------------------------------------------------------
import { AclModule } from './acl/acl.module';
import { AuthModule } from './auth/auth.module';
import { BusModule } from './bus/bus.module';
import { ProfileModule } from './profile/profile.module';
import { DoctorModule } from './doctor/doctor.module';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { ConfigModule } from './config/config.module';
import { RequestModule } from './request/request.module';
import { WebsocketModule } from './websocket/websocket.module';
import { WorkspacesModule } from './workspaces/workspaces.module';
import { VersionModule } from './version/version.module';
import { SaasModule } from './saas/saas.module';
// -------------------------------------------------------------------------------------------------

/**
 * # Modules
 * Aggregate all modules
 */
@injectable()
export class Modules {
  constructor(
    @inject(TOKENS.AclModule) public readonly acl: AclModule,
    @inject(TOKENS.AuthModule) public readonly auth: AuthModule,
    @inject(TOKENS.BusModule) public readonly bus: BusModule,
    @inject(TOKENS.ProfileModule) public readonly profile: ProfileModule,
    @inject(TOKENS.DoctorModule) public readonly doctor: DoctorModule,
    @inject(TOKENS.MaintenanceModule) public readonly maintenance: MaintenanceModule,
    @inject(TOKENS.ConfigModule) public readonly config: ConfigModule,
    @inject(TOKENS.RequestModule) public readonly request: RequestModule,
    @inject(TOKENS.WebsocketModule) public readonly websocket: WebsocketModule,
    @inject(TOKENS.WorkspacesModules) public readonly workspaces: WorkspacesModule,
    @inject(TOKENS.VersionModule) public readonly version: VersionModule,
    @inject(TOKENS.SaasModule) public readonly saas: SaasModule
  ) {}
}
