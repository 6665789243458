import { isUndefined, isString, isBoolean, isNumber } from 'lodash';

import { getValueFromEnv } from './utils/getValueFromEnv';

import { ConfigTypeError } from './errors/ConfigTypeError';
import { ConfigNotFoundError } from './errors/ConfigNotFoundError';

import type { ValuePrimitiveType, ValueType } from './types';
import { IOptions } from './utils/convertKeyToEnvName';

/**
 * Get a value from the environment
 */
export function get<T extends ValuePrimitiveType>(
  key: string,
  options: {
    type: T;
    defaultValue: ValueType<T>;
    prefix?: IOptions['prefix'];
    package?: IOptions['package'];
  }
): ValueType<T>;
export function get<T extends ValuePrimitiveType>(
  key: string,
  options: {
    type: T;
    defaultValue?: ValueType<T>;
    prefix?: IOptions['prefix'];
    package?: IOptions['package'];
  }
): ValueType<T> | undefined;
export function get<T extends ValuePrimitiveType>(
  key: string,
  options?: {
    type?: T;
    defaultValue?: ValueType<T>;
    prefix?: IOptions['prefix'];
    package?: IOptions['package'];
  }
): ValueType<T> | undefined {
  const value = options?.prefix
    ? options?.package
      ? getValueFromEnv(key, { prefix: options.prefix, package: options.package })
      : getValueFromEnv(key, { prefix: options.prefix })
    : getValueFromEnv(key);

  // If undefined, return default value
  if (isUndefined(value)) {
    return options?.defaultValue;
  }

  switch (options?.type) {
    // Validate "boolean"
    case 'boolean':
      if (isBoolean(value)) {
        return value as ValueType<T>;
      } else if (isString(value)) {
        if (value === 'true') {
          return true as ValueType<T>;
        } else if (value === 'false') {
          return false as ValueType<T>;
        }
      }
      throw new ConfigTypeError(key, 'boolean', typeof value);
    // Validate "string"
    case 'string':
      if (isString(value)) {
        return value as ValueType<T>;
      }
      throw new ConfigTypeError(key, 'string', typeof value);
    // Validate "number"
    case 'number':
      if (isNumber(value)) {
        return value as ValueType<T>;
      } else if (isString(value)) {
        const n = +value.trim();
        if (!Number.isNaN(n)) {
          return n as ValueType<T>;
        }
      }
      throw new ConfigTypeError(key, 'string', typeof value);
    // Without type validation
    default:
      return value as ValueType<T>;
  }
}

export function getOrThrow<T extends ValuePrimitiveType>(...args: Parameters<typeof get>): ValueType<T> {
  const value = get(...args);

  if (isUndefined(value)) {
    throw new ConfigNotFoundError(...args);
  }

  return value as ValueType<T>;
}
