import { convertKeyToEnvName } from '../utils/convertKeyToEnvName';

import type { get } from '../retrievers';

/**
 * The declared property was not found
 */
export class ConfigNotFoundError extends Error {
  public readonly name: string = 'ConfigNotFoundError';

  public readonly key: string;

  public constructor(...args: Parameters<typeof get>) {
    super();

    this.key = args[0];

    this.message = `The configuration key "${
      this.key
    }" cannot be linked with an environment variable, please provide one named "${convertKeyToEnvName(
      args[0],
      { prefix: args[1].prefix, package: args[1].package }
    )}".`;
  }
}
