// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
import { actions, assign, sendParent } from 'xstate';
import { map } from 'rxjs/operators';
import { compact } from 'lodash';
// Import factories --------------------------------------------------------------------------------
import { ModuleWithStateFactory } from '@/plugins/podocore/factories/ModuleWithState.factory';
// Import helpers ----------------------------------------------------------------------------------
import { createModuleStateMachine } from '@/plugins/podocore/helpers/modules.helpers';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
import { versionConfig } from '@/config/version.config';
// Import utils ------------------------------------------------------------------------------------
import { useVersion } from '@/utils/version.utils';
// Import types ------------------------------------------------------------------------------------
import type { VersionEntity } from '@digitsole/blackburn-entities/dist/entities/version/version.entity';
// -------------------------------------------------------------------------------------------------

@injectable()
export class VersionModule extends ModuleWithStateFactory {
  constructor() {
    super();

    const stage = process.env['BLACKBURN__STAGE'];
    const disableVersionCheck = process.env['VUE_APP_DISABLE_VERSION_CHECK'];
    const { currentVersion } = useVersion();

    const version = compact([
      currentVersion.value,
      stage && stage !== 'production' ? stage : undefined,
      versionConfig.default
    ]).join('-');

    this._machine = createModuleStateMachine(
      this._name,
      {
        initial: 'fetching',
        context: {
          fetchingError: undefined,
          currentVersion: version,
          newVersion: undefined,
          isFetchedAtLeastOnce: false
        },
        states: {
          fetching: {
            invoke: {
              src: 'fetchVersion',
              onDone: [
                {
                  cond(_, { data }) {
                    return data.semver !== version && !disableVersionCheck;
                  },
                  actions: assign({
                    newVersion: (_, { data }) => data.semver
                  }),
                  target: 'versionNotEqual'
                },
                {
                  actions: assign({
                    isFetchedAtLeastOnce: ({ isFetchedAtLeastOnce }, { data }) => {
                      if (!isFetchedAtLeastOnce) console.log(`🆚 Version : ${data.semver}`);
                      return true;
                    }
                  }),
                  target: 'success'
                }
              ],
              onError: {
                actions: assign({
                  fetchingError: (_, { data }) => data
                }),
                target: 'failure'
              }
            }
          },
          success: {
            entry: [actions.pure(() => sendParent({ type: 'FETCHED' }))],
            on: {
              FETCH: 'fetching'
            },
            after: {
              3600000: 'fetching'
            }
          },
          failure: {
            entry: [actions.pure((context) => sendParent({ type: 'FATAL', data: context.fetchingError }))],
            exit: assign({
              fetchingError: () => undefined
            }),
            after: {
              300000: 'fetching'
            },
            on: {
              FETCH: 'fetching'
            }
          },
          versionNotEqual: {
            entry: [actions.pure(() => sendParent({ type: 'NOT_EQUAL' }))]
          }
        }
      },
      {
        services: {
          fetchVersion: this.fetchVersion.bind(this)
        }
      }
    );
  }

  async fetchVersion(): Promise<VersionEntity> {
    return this._core
      .getModule('request')
      .request<VersionEntity>(`${apiConfig.default}/versions/com.digitsolepro.blackburn.app.browser`)
      .pipe(map((response) => response.data))
      .toPromise();
  }
}
