// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
import { WebsocketClient } from '@digitsole/blackburn-websocket-client';
import { actions, assign, sendParent } from 'xstate';
import { map } from 'rxjs/operators';
// Import configurations ---------------------------------------------------------------------------
import { websocketConfig } from '@/config/websocket.config';
// Import factories --------------------------------------------------------------------------------
import { ModuleWithStateFactory } from '../../factories/ModuleWithState.factory';
// Import helpers ----------------------------------------------------------------------------------
import { createModuleStateMachine } from '@/plugins/podocore/helpers/modules.helpers';
// -------------------------------------------------------------------------------------------------

/**
 * Websocket module
 */
@injectable()
export class WebsocketModule extends ModuleWithStateFactory {
  constructor() {
    super();

    this._machine = createModuleStateMachine(
      this._name,
      {
        initial: 'init',
        context: {
          client: undefined,
          notifications: [],
          error: undefined
        },
        states: {
          init: {
            invoke: {
              src: 'createClient',
              onDone: {
                actions: assign({
                  client: (_, { data }) => data
                }),
                target: 'created'
              },
              onError: {
                actions: assign({
                  error(_, { data }) {
                    return data;
                  }
                }),
                target: 'failure'
              }
            }
          },
          created: {
            entry: actions.pure(() => {
              return sendParent({ type: 'CREATED' });
            }),
            activities: ['listenMessages']
          },
          failure: {
            entry: actions.pure((context) => {
              return sendParent({ type: 'FATAL', data: context.error });
            }),
            exit: assign({
              error(_) {
                return undefined;
              }
            })
          }
        }
      },
      {
        services: {
          createClient: this._createClient.bind(this)
        },
        activities: {
          listenMessages: (context) => {
            const subscription = context.client.subscribe({
              next: this._broadcast.bind(this)
            });

            return () => subscription.complete();
          }
        }
      }
    );
  }

  private async _createClient() {
    return this._retrieveToken()
      .pipe(
        map((user) => {
          const userId = user.attributes.sub;
          const accessToken = user.signInUserSession.accessToken.jwtToken;

          const client = new WebsocketClient(
            `${websocketConfig.url}?userId=${userId}&deviceType=web`,
            accessToken,
            {
              debug: true
            }
          );

          return client.createClient$(
            this._retrieveToken().pipe(map((user) => user.signInUserSession.accessToken.jwtToken))
          );
        })
      )
      .toPromise();
  }

  private _broadcast(message: any) {
    const busModule = this._core.getModule('bus');
    return busModule.publish(busModule.events.createNotification({ message }));
  }

  private _retrieveToken() {
    return this._core.getModule('auth').getUser();
  }
}
