// Import vendors ----------------------------------------------------------------------------------
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { merge } from 'lodash';
// Import utils ------------------------------------------------------------------------------------
import { navigatorLang } from '@/utils/i18n.utils';
// Import types ------------------------------------------------------------------------------------
import type { LocaleMessages } from 'vue-i18n';

// Export / declare types --------------------------------------------------------------------------
export type Country = {
  code: string;
  name: string;
  dial_code: string;
};
// -------------------------------------------------------------------------------------------------

Vue.use(VueI18n);

/**
 * Load local messages
 */
function loadLocaleMessages(): LocaleMessages {
  const localesFiles = require.context('@/locales', true, /[a-z]{2}(-[a-z]{2})?\.json$/i);

  const messages: LocaleMessages = {};

  localesFiles
    .keys()
    .map((localesFileKey) => {
      return [localesFileKey, /([a-z]{2}(-[a-z]{2})?)(?:\/.*)?\.json/i.exec(localesFileKey)];
    })
    .forEach(([key, matches]) => {
      if (matches && matches.length > 1) {
        messages[matches[1]] = merge(messages[matches[1]], localesFiles(key as string));
      }
    });

  // TODO : implement duplication analyzer

  return messages;
}

export default new VueI18n({
  locale: navigatorLang(),
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
});
