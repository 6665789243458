// Import vendors ----------------------------------------------------------------------------------
import { assign, createMachine } from 'xstate';
import { Auth } from '@aws-amplify/auth';
// Import helpers ----------------------------------------------------------------------------------
import { assertEventType } from '@/helpers/xstate.helper';
// Import types ------------------------------------------------------------------------------------
import type { AnyEventObject } from 'xstate';
// -------------------------------------------------------------------------------------------------

/**
 * Reset password machine
 */
export const resetPasswordMachine = createMachine(
  {
    id: 'resetPassword',
    context: {
      username: undefined,
      sendingCodeError: undefined,
      submittingError: undefined
    },
    initial: 'idle',
    states: {
      idle: {
        entry: assign({
          username(_) {
            return undefined;
          }
        }),
        on: {
          SEND_CODE: {
            actions: assign({
              username(_, event: AnyEventObject) {
                return event.data.username;
              }
            }),
            target: '#resetPassword.sendingCode'
          },
          SKIP_SEND: '#resetPassword.waitingUserCompletion'
        }
      },
      sendingCode: {
        initial: 'processing',
        states: {
          processing: {
            invoke: {
              src: 'sendCode',
              onDone: '#resetPassword.waitingUserCompletion',
              onError: {
                actions: assign({
                  sendingCodeError(_, { data }) {
                    return data;
                  }
                }),
                target: 'failure'
              }
            }
          },
          failure: {
            exit: assign({
              sendingCodeError(_) {
                return undefined;
              }
            }),
            on: {
              SEND_CODE: 'processing',
              SKIP_SEND: '#resetPassword.waitingUserCompletion',
              RESET: '#resetPassword.idle'
            }
          }
        }
      },
      waitingUserCompletion: {
        on: {
          SUBMIT: '#resetPassword.submitting',
          RESET: '#resetPassword.idle'
        }
      },
      submitting: {
        initial: 'processing',
        states: {
          processing: {
            invoke: {
              src: 'submit',
              onDone: '#resetPassword.success',
              onError: {
                actions: assign({
                  submittingError(_, { data }) {
                    return data;
                  }
                }),
                target: 'failure'
              }
            }
          },
          failure: {
            exit: assign({
              submittingError(_) {
                return undefined;
              }
            }),
            on: {
              SUBMIT: 'processing',
              RESET: '#resetPassword.idle'
            }
          }
        }
      },
      success: {
        on: {
          RESET: 'idle'
        }
      }
    }
  },
  {
    services: {
      async sendCode(_, event: AnyEventObject) {
        return Auth.forgotPassword(event.data.username);
      },
      async submit(context: any, event: AnyEventObject) {
        return Auth.forgotPasswordSubmit(
          event.data.username || context.username,
          event.data.code,
          event.data.password
        );
      }
    }
  }
);
