// Import vendors ----------------------------------------------------------------------------------
import { inject, injectable } from 'inversify';
import { interpret, createMachine, send, assign } from 'xstate';
import { inspect } from '@xstate/inspect';
import { from } from 'rxjs';
// Import helpers ----------------------------------------------------------------------------------
import { invokeModulesMachine } from './helpers/modules.helpers';
// Import plugins ----------------------------------------------------------------------------------
import { TOKENS } from './tokens';
// Import modules ----------------------------------------------------------------------------------
import { Modules } from './modules';
// Import repositories -----------------------------------------------------------------------------
import { Repositories } from './repositories';
// Import types ------------------------------------------------------------------------------------
import type { AnyEventObject } from 'xstate';
import type { Observable, ObservableInput } from 'rxjs';
// -------------------------------------------------------------------------------------------------

if (process.env.VUE_APP_INSPECT_STATE) {
  console.log('🚧 Start state inspector');

  inspect({
    iframe: false
  });
}

/**
 * # Podocore
 * Services manager
 *
 * @author Damien CORLER <d.corler@zhortech.com>
 * @version 2.0.1
 */
@injectable()
export class Podocore {
  private readonly _service;

  constructor(
    @inject(TOKENS.Modules) private readonly _modules: Modules,
    @inject(TOKENS.Repositories) private readonly _repositories: Repositories
  ) {
    console.log('🚀 Podocore - 2.4.0 - By Digitsole');

    // Main state machine
    const machine = createMachine({
      id: 'podocore',
      initial: 'bootstrap',
      invoke: invokeModulesMachine(this._modules),
      context: {
        error: undefined,
        cancelReason: undefined,
        info: undefined
      },
      states: {
        bootstrap: {
          initial: 'checkingMaintenance',
          states: {
            checkingMaintenance: {
              entry: send({ type: 'ENABLE' }, { to: 'maintenance' }),
              on: {
                'maintenance.INACTIVE': 'fetchingConfig',
                'maintenance.INCOMING': {
                  actions: assign({
                    cancelReason: (_) => undefined,
                    info: (_, event) => (event as any).data.incoming[0]
                  }),
                  target: 'fetchingConfig'
                },
                'maintenance.ACTIVE': {
                  actions: assign({
                    cancelReason: (_) => 'maintenance',
                    info: (_, event) => (event as any).data.current[0]
                  }),
                  target: '#podocore.cancelled'
                }
              }
            },
            fetchingConfig: {
              entry: send({ type: 'ENABLE' }, { to: 'config' }),
              on: {
                'config.FETCHED': 'checkingVersion',
                'config.FATAL': {
                  actions: [
                    assign({
                      error(_, event: AnyEventObject) {
                        console.error('CONFIG ERROR', event.data);
                        return event.data;
                      }
                    })
                  ],
                  target: '#podocore.fatal'
                }
              }
            },
            checkingVersion: {
              entry: send({ type: 'ENABLE' }, { to: 'version' }),
              on: {
                'version.FETCHED': 'checkingAuthentication',
                'version.NOT_EQUAL': {
                  actions: assign({
                    cancelReason: (_) => 'version'
                  }),
                  target: '#podocore.cancelled'
                },
                'version.FATAL': {
                  actions: [
                    assign({
                      error(_, event: AnyEventObject) {
                        console.error('VERSION ERROR', event.data);
                        return event.data;
                      }
                    })
                  ],
                  target: '#podocore.fatal'
                }
              }
            },
            checkingAuthentication: {
              entry: send({ type: 'ENABLE' }, { to: 'auth' }),
              on: {
                'auth.AUTHENTICATED': 'fetchingProfile',
                'auth.UNAUTHENTICATED': 'fetchingAbilities'
              }
            },
            // fetchingAbilities: {
            //   entry: send({ type: 'ENABLE' }, { to: 'acl' }),
            //   on: {
            //     'acl.PROVISIONED': [
            //       {
            //         cond: () => this.getModuleService('auth').state.matches('authenticated'),
            //         target: 'fetchingProfile'
            //       },
            //       '#podocore.ready'
            //     ],

            //     'acl.FATAL': {
            //       actions: [
            //         assign({
            //           error(_, event: AnyEventObject) {
            //             return event.data;
            //           }
            //         })
            //       ],
            //       target: '#podocore.fatal'
            //     }
            //   }
            // },
            // fetchingPlan: {
            //   entry: send({ type: 'ENABLE' }, { to: 'plan' }),
            //   on: {
            //     'plan.FETCHED': 'fetchingProfile',

            //     'plan.FATAL': {
            //       actions: [
            //         assign({
            //           error(_, event: AnyEventObject) {
            //             console.error('PLAN ERROR', event.data);
            //             return event.data;
            //           }
            //         })
            //       ],
            //       target: '#podocore.fatal'
            //     }
            //   }
            // },
            fetchingProfile: {
              entry: send({ type: 'ENABLE' }, { to: 'profile' }),
              on: {
                'profile.FETCHED': 'fetchingDoctor',

                'profile.FATAL': {
                  actions: [
                    assign({
                      error(_, event: AnyEventObject) {
                        console.error('PROFILE ERROR', event.data);
                        return event.data;
                      }
                    })
                  ],
                  target: '#podocore.fatal'
                }
              }
            },
            fetchingDoctor: {
              entry: send({ type: 'ENABLE' }, { to: 'doctor' }),
              on: {
                'doctor.FETCHED': 'fetchingWorkspaces',

                'doctor.NOT_EXIST': '#podocore.ready',

                'doctor.NOT_COMPLETE': '#podocore.ready',

                'doctor.FATAL': {
                  actions: [
                    assign({
                      error(_, event: AnyEventObject) {
                        console.error('DOCTOR ERROR', event.data);
                        return event.data;
                      }
                    })
                  ],
                  target: '#podocore.fatal'
                }
              }
            },
            fetchingWorkspaces: {
              entry: send({ type: 'ENABLE' }, { to: 'workspaces' }),
              on: {
                'workspaces.FETCHED': 'fetchingAbilities',

                'workspaces.FATAL': {
                  actions: [
                    assign({
                      error(_, event: AnyEventObject) {
                        console.error('WORKSPACE ERROR', event.data);
                        return event.data;
                      }
                    })
                  ],
                  target: '#podocore.fatal'
                }
              }
            },
            fetchingAbilities: {
              entry: send({ type: 'ENABLE' }, { to: 'acl' }),
              on: {
                'acl.PROVISIONED': [
                  {
                    cond: () => this.getModuleService('auth').state.matches('authenticated'),
                    target: 'fetchingSaas'
                  },
                  {
                    target: '#podocore.ready'
                  }
                ],
                'acl.FATAL': {
                  actions: [
                    assign({
                      error(_, event: AnyEventObject) {
                        return event.data;
                      }
                    })
                  ],
                  target: '#podocore.fatal'
                }
              }
            },
            fetchingSaas: {
              entry: send({ type: 'ENABLE' }, { to: 'saas' }),
              on: {
                'saas.FETCHED': [
                  {
                    cond: () => this.getModuleService('auth').state.matches('authenticated'),
                    target: 'connecting'
                  },
                  {
                    target: '#podocore.ready'
                  }
                ],
                'saas.FATAL': {
                  actions: [
                    assign({
                      error(_, event: AnyEventObject) {
                        return event.data;
                      }
                    })
                  ],
                  target: '#podocore.fatal'
                }
              }
            },
            connecting: {
              entry: send({ type: 'ENABLE' }, { to: 'websocket' }),
              on: {
                'websocket.CREATED': '#podocore.ready',
                'websocket.FATAL': {
                  actions: [
                    assign({
                      error(_, event: AnyEventObject) {
                        return event.data;
                      }
                    })
                  ],
                  // NOT FATAL
                  target: '#podocore.ready'
                }
              }
            }
          }
        },
        cancelled: {
          on: {
            'maintenance.ACTIVE': {
              actions: assign({
                info: (_, event) => (event as any).data.current[0]
              })
            },
            'maintenance.INACTIVE': {
              actions: () => window.location.reload()
            },
            'maintenance.INCOMING': {
              actions: () => window.location.reload()
            }
          }
        },
        fatal: {},
        ready: {
          on: {
            'maintenance.ACTIVE': {
              actions: assign({
                cancelReason: (_) => 'maintenance',
                info: (_, event) => (event as any).data.current[0]
              }),
              target: '#podocore.cancelled'
            }
          }
        }
      }
    } as any);

    // Create service and start it
    this._service = interpret(machine, { devTools: !!process.env.VUE_APP_INSPECT_STATE }).start();
  }

  public getModule<M extends keyof Modules>(moduleId: M): Modules[M] {
    return this._modules[moduleId];
  }

  public getModuleService(id: keyof Modules, getRoot = false) {
    const root = this._service.children.get(id) as any;

    if (getRoot) {
      return root;
    }

    return root?.children.get('module');
  }

  public getRepository<R extends keyof Repositories>(repositoryId: R): Repositories[R] {
    return this._repositories[repositoryId];
  }

  get service() {
    return this._service;
  }

  get state$(): Observable<any> {
    return from(this.service as ObservableInput<any>);
  }
}
