// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
import { actions, assign, sendParent } from 'xstate';
import { map } from 'rxjs/operators';
// Import factories --------------------------------------------------------------------------------
import { ModuleWithStateFactory } from '@/plugins/podocore/factories/ModuleWithState.factory';
// Import helpers ----------------------------------------------------------------------------------
import { createModuleStateMachine } from '@/plugins/podocore/helpers/modules.helpers';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Export / declare types --------------------------------------------------------------------------
interface ProfilePodosmart {
  cognitoId: string;
  migrated: boolean;
}

export interface Profile {
  cuid: string;
  cognitoId: string;
  email: string;
  emailVerified: boolean;
  podosmart?: ProfilePodosmart;
}
// -------------------------------------------------------------------------------------------------

/**
 * Profile module
 * @deprecated use doctor module instead
 */
@injectable()
export class ProfileModule extends ModuleWithStateFactory {
  constructor() {
    super();

    const commonsEvents = {
      CHECK: '#module.fetching'
    };

    this._machine = createModuleStateMachine(
      this._name,
      {
        initial: 'fetching',
        context: {
          profile: undefined,
          error: undefined
        },
        states: {
          fetching: {
            invoke: {
              src: 'fetchProfile',
              onDone: {
                actions: [
                  assign({
                    profile: (_, { data }) => data
                  }),
                  function () {
                    console.log('💊 Profile updated');
                  }
                ],
                target: 'success'
              },
              onError: {
                actions: assign({
                  error(_, { data }) {
                    return data;
                  }
                }),
                target: 'failure'
              }
            }
          },
          success: {
            entry: [actions.pure(() => sendParent({ type: 'FETCHED' }))],
            on: {
              ...commonsEvents
            }
          },
          failure: {
            entry: actions.pure((context) => {
              return sendParent({ type: 'FATAL', data: context.error });
            }),
            exit: assign({
              error(_) {
                return undefined;
              }
            }),
            on: commonsEvents
          }
        }
      },
      {
        services: {
          fetchProfile: this.fetchProfile.bind(this)
        }
      }
    );
  }

  async fetchProfile(): Promise<Profile> {
    return this._core
      .getModule('request')
      .authenticatedRequest<Profile>(`${apiConfig.default}/user`)
      .pipe(map((response) => response.data))
      .toPromise();
  }
}
