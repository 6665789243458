// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { actions, assign, send, sendParent } from 'xstate';
import { defer, throwError } from 'rxjs';
import { map, skipWhile, take, tap } from 'rxjs/operators';
import { datadogRum } from '@datadog/browser-rum';
// Import factories --------------------------------------------------------------------------------
import { ModuleWithStateFactory } from '../../factories/ModuleWithState.factory';
// Import helpers ----------------------------------------------------------------------------------
import { createModuleStateMachine } from '../../helpers/modules.helpers';
import { assertEventType } from '@/helpers/xstate.helper';
// Import configurations ---------------------------------------------------------------------------
import { cognitoConfig } from '@/config/cognito.config';
import { apiConfig } from '@/config/api.config';
// Import machines ---------------------------------------------------------------------------------
import { signUpMachine } from '@/plugins/podocore/modules/auth/machines/sign-up.machine';
import { signInMachine } from '@/plugins/podocore/modules/auth/machines/sign-in.machine';
import { resetPasswordMachine } from '@/plugins/podocore/modules/auth/machines/reset-password.machine';
import { mfaMachine } from '@/plugins/podocore/modules/auth/machines/mfa.machine';
// Import types ------------------------------------------------------------------------------------
import type { Observable } from 'rxjs';
import type { Profile } from '../profile/profile.module';
// Export / declare types --------------------------------------------------------------------------
export type AwsError = {
  code: string;
  name: string;
  message: string;
};

export type AwsFriendlyError = AwsError & {
  code: 'UserNotConfirmedException';
};

export type Credentials = {
  username: string;
  password: string;
};

export type User = {
  username: string;
  signInUserSession: {
    accessToken: {
      jwtToken: string;
    };
  };
  challengeName: string;
  attributes: {
    email: string;
    sub: string;
  };
};
// -------------------------------------------------------------------------------------------------

// Configure auth provider
Amplify.configure({
  Auth: {
    region: cognitoConfig.region,
    userPoolId: cognitoConfig.userPoolId,
    userPoolWebClientId: cognitoConfig.userPoolWebClientId,
    identityPoolId: cognitoConfig.identityPoolId,
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  }
});

/**
 * Auth module
 */
@injectable()
export class AuthModule extends ModuleWithStateFactory {
  constructor() {
    super();

    this._machine = createModuleStateMachine(
      this._name,
      {
        context: AuthModule.getDefaultContext(),
        initial: 'init',
        states: {
          init: {
            initial: 'checkingLocalCredentials',
            states: {
              checkingLocalCredentials: {
                invoke: {
                  src: 'getUser',
                  onDone: {
                    actions: assign({
                      user(_, event) {
                        return event.data;
                      }
                    }),
                    target: '#module.authenticated'
                  },
                  onError: [
                    {
                      cond(context, event) {
                        return event.data === 'The user is not authenticated';
                      },
                      target: '#module.unauthenticated'
                    },
                    {
                      actions: assign({
                        error(context, event) {
                          return event.data;
                        }
                      }),
                      target: '#module.failure'
                    }
                  ]
                }
              }
            }
          },

          unauthenticated: {
            entry: actions.pure((_) => {
              console.log('🔒 Unauthenticated');

              // Disable services
              this._core.getModuleService('profile', true).send({ type: 'DISABLE' });
              this._core.getModuleService('doctor', true).send({ type: 'DISABLE' });
              this._core.getModuleService('websocket', true).send({ type: 'DISABLE' });
              this._core.getModuleService('workspaces', true).send({ type: 'DISABLE' });
              this._core.getModuleService('saas', true).send({ type: 'DISABLE' });

              return [
                sendParent({ type: 'UNAUTHENTICATED' }),
                assign({
                  user() {
                    return undefined;
                  }
                })
              ];
            }),
            exit: assign({
              credentials(_) {
                return undefined;
              }
            }),
            invoke: [
              {
                id: signUpMachine.id,
                src: signUpMachine
              },
              {
                id: signInMachine.id,
                src: signInMachine
              },
              {
                id: resetPasswordMachine.id,
                src: resetPasswordMachine
              }
            ],
            on: {
              SIGNED_IN: {
                actions: [
                  assign({
                    user(_, { data }) {
                      return data.user;
                    }
                  })
                ],
                target: '#module.fetching'
              },
              SIGNED_UP: {
                actions: actions.pure((_, { data }) => {
                  return send({ type: 'SIGN_IN', data }, { to: signInMachine.id });
                })
              },
              // Necessary to unlock steps like code confirmation
              SIGN_OUT: {
                actions: () => {
                  window.location.reload();
                }
              }
            }
          },

          fetching: {
            entry: actions.pure(() => {
              console.log('⏳ Fetching ...');
              return sendParent({ type: 'FETCHING' });
            }),
            initial: 'doctor',
            states: {
              /* profile: {
                on: {
                  SIGN_OUT: '#module.authenticated.clearing'
                },
                invoke: {
                  src: async () => {
                    // Retrieve profile module
                    const profileService = this._core.getModule('profile');
                    // Observe state mutations
                    return defer(() => {
                      // Enable profile service
                      this._core.getModuleService('profile', true).send({ type: 'ENABLE' });
                      // Return observable of machine state
                      return profileService.state$;
                    })
                      .pipe(
                        skipWhile((state) => {
                          return !['success', 'failure'].some((element) => state.matches(element));
                        }),
                        map((state) => {
                          return state.context.error
                            ? throwError(new Error(state.context.error))
                            : state.context.profile;
                        }),
                        take(1)
                      )
                      .toPromise();
                  },
                  onDone: [
                    {
                      cond: (_, { data }) => data.podosmart && !data.podosmart?.migrated,
                      target: '#module.migrating'
                    },
                    {
                      target: 'doctor'
                    }
                  ],
                  onError: {
                    actions: assign({
                      error(_, { data }) {
                        console.log('GET PROFILE ERROR', data);
                        return data;
                      }
                    }),
                    target: '#module.failure'
                  }
                }
              }, */
              doctor: {
                on: {
                  SIGN_OUT: '#module.authenticated.clearing'
                },
                invoke: {
                  src: async () => {
                    // Retrieve doctor module
                    const doctorService = this._core.getModule('doctor');
                    // Observe state mutations
                    return defer(() => {
                      // Enable doctor service
                      this._core.getModuleService('doctor', true).send({ type: 'ENABLE' });
                      // Return observable of machine state
                      return doctorService.state$;
                    })
                      .pipe(
                        skipWhile((state) => {
                          return !['success', 'failure'].some((element) => state.matches(element));
                        }),
                        map((state) => {
                          return state.context.error
                            ? throwError(new Error(state.context.error))
                            : state.context.doctor;
                        }),
                        take(1)
                      )
                      .toPromise();
                  },
                  onDone: '#module.connecting',
                  onError: {
                    actions: assign({
                      error(_, { data }) {
                        console.log('GET DOCTOR ERROR', data);
                        return data;
                      }
                    }),
                    target: '#module.failure'
                  }
                }
              } /* ,
              abilities: {
                invoke: {
                  src: async () => {
                    // Retrieve ACL service
                    const aclService = this._core.getModule('acl');
                    // Observe state mutations
                    return defer(() => {
                      // Send check request
                      aclService.service.send({ type: 'CHECK' });
                      // Return observable of machine state
                      return aclService.state$;
                    })
                      .pipe(
                        skipWhile((state) => {
                          return !['success', 'failure'].some((element) => state.matches(element));
                        }),
                        map((state) => {
                          return state.context.error
                            ? throwError(new Error(state.context.error))
                            : state.context.abilities;
                        }),
                        take(1)
                      )
                      .toPromise();
                  },
                  onDone: '#module.connecting',
                  onError: {
                    actions: assign({
                      error(_, { data }) {
                        return data;
                      }
                    }),
                    target: '#module.failure'
                  }
                }
              } */
            }
          },

          migrating: {
            entry: actions.pure(() => {
              console.log('⏳ Migrating...');
              return sendParent({ type: 'MIGRATING' });
            }),
            activities: ['checkMigrationState']
          },

          connecting: {
            entry: actions.pure(() => {
              console.log('⏳ Connecting socket ...');
              return sendParent({ type: 'CONNECTING' });
            }),
            invoke: {
              src: async () => {
                // Retrieve websocket module
                const websocketService = this._core.getModule('websocket');
                // Observe state mutations
                return defer(() => {
                  // Enable doctor service
                  this._core.getModuleService('websocket', true).send({ type: 'ENABLE' });
                  // Return observable of machine state
                  return websocketService.state$;
                })
                  .pipe(
                    skipWhile((state) => {
                      return !['created', 'failure'].some((element) => state.matches(element));
                    }),
                    map((state) => {
                      return state.context.error
                        ? throwError(new Error(state.context.error))
                        : state.context.client;
                    }),
                    take(1)
                  )
                  .toPromise();
              },
              onDone: [
                {
                  target: '#module.configuringWorkspace',
                  cond: () => !this._core.getModuleService('doctor').state.matches('creating')
                },
                { target: '#module.authenticated' }
              ],
              onError: {
                actions: assign({
                  error(_, { data }) {
                    console.log('GET WEBSOCKET ERROR', data);
                    return data;
                  }
                }),
                target: '#module.failure'
              }
            }
          },

          configuringWorkspace: {
            entry: actions.pure(() => {
              console.log('⏳ Configuring workspace ...');
              return sendParent({ type: 'CONFIGURING_WORKSPACE' });
            }),
            invoke: {
              src: async () => {
                // Retrieve websocket module
                const workspacesService = this._core.getModule('workspaces');
                // Observe state mutations
                return defer(() => {
                  // Enable doctor service
                  this._core.getModuleService('workspaces', true).send({ type: 'ENABLE' });
                  // Return observable of machine state
                  return workspacesService.state$;
                })
                  .pipe(
                    skipWhile((state) => {
                      return !['success', 'failure'].some((element) => state.matches(element));
                    }),
                    map((state) => {
                      return state.context.error
                        ? throwError(new Error(state.context.error))
                        : state.context.client;
                    }),
                    take(1)
                  )
                  .toPromise();
              },
              onDone: '#module.abilities',
              onError: {
                actions: assign({
                  error(_, { data }) {
                    console.log('GET WORKSPACE ERROR', data);
                    return data;
                  }
                }),
                target: '#module.failure'
              }
            }
          },

          abilities: {
            invoke: {
              src: async () => {
                // Retrieve ACL service
                const aclService = this._core.getModule('acl');
                // Observe state mutations
                return defer(() => {
                  // Send check request
                  aclService.service.send({ type: 'CHECK' });
                  // Return observable of machine state
                  return aclService.state$;
                })
                  .pipe(
                    skipWhile((state) => {
                      return !['success', 'failure'].some((element) => state.matches(element));
                    }),
                    map((state) => {
                      return state.context.error
                        ? throwError(new Error(state.context.error))
                        : state.context.abilities;
                    }),
                    take(1)
                  )
                  .toPromise();
              },
              onDone: '#module.saas',
              onError: {
                actions: assign({
                  error(_, { data }) {
                    return data;
                  }
                }),
                target: '#module.failure'
              }
            }
          },

          saas: {
            invoke: {
              src: async () => {
                // Retrieve saas module
                const saasService = this._core.getModule('saas');
                // Observe state mutations
                return defer(() => {
                  // Enable saas service
                  this._core.getModuleService('saas', true).send({ type: 'ENABLE' });
                  // Return observable of machine state
                  return saasService.state$;
                })
                  .pipe(
                    skipWhile((state) => {
                      return !['success', 'failure'].some((element) => state.matches(element));
                    }),
                    map((state) => {
                      return state.context.error
                        ? throwError(new Error(state.context.error))
                        : state.context.currentSubscription;
                    }),
                    take(1)
                  )
                  .toPromise();
              },
              onDone: '#module.authenticated',
              onError: {
                actions: assign({
                  error(_, { data }) {
                    return data;
                  }
                }),
                target: '#module.failure'
              }
            }
          },

          authenticated: {
            entry: [
              actions.pure(() => {
                console.log('🔓 Authenticated');
                return sendParent({ type: 'AUTHENTICATED' });
              })
            ],
            invoke: [
              {
                id: mfaMachine.id,
                src: mfaMachine
              }
            ],
            initial: 'idle',
            states: {
              idle: {
                on: {
                  SIGN_OUT: '#module.authenticated.clearing',
                  CHECK: 'checking'
                }
              },
              checking: {
                invoke: {
                  src: 'getUser',
                  onDone: {
                    actions: assign({
                      user(_, event) {
                        return event.data;
                      }
                    }),
                    target: '#module.authenticated'
                  },
                  onError: [
                    {
                      cond(_, event) {
                        return event.data === 'The user is not authenticated';
                      },
                      target: '#module.unauthenticated'
                    },
                    {
                      actions: assign({
                        error(_, event) {
                          return event.data;
                        }
                      }),
                      target: '#module.failure'
                    }
                  ]
                }
              },
              clearing: {
                invoke: {
                  src: 'signOut',
                  onDone: {
                    actions: assign(AuthModule.getDefaultContext),
                    target: '#module.unauthenticated'
                  },
                  onError: {
                    actions: assign((_, event) => ({
                      ...AuthModule.getDefaultContext(),
                      error: event.data
                    })),
                    target: '#module.failure'
                  }
                }
              }
            }
          },

          // If an unknown issue occurs, delete local storage and retry init
          failure: {
            entry(_, event) {
              localStorage.clear();

              console.error(event.data);
            },
            exit: assign(AuthModule.getDefaultContext),
            after: {
              5000: 'init'
            }
          }
        }
      },
      {
        services: {
          async getUser() {
            try {
              const authenticatedUser = await Auth.currentAuthenticatedUser();
              (window as any).amplitude.setUserId(authenticatedUser.attributes.sub);
              return authenticatedUser;
            } catch (error) {
              (window as any).amplitude.setUserId(null);
              throw error;
            }
          },
          async signOut(_, event) {
            assertEventType(event, 'SIGN_OUT');
            (window as any).amplitude.setUserId(null);
            return Auth.signOut({ global: event.data?.global });
          }
        },
        activities: {
          checkMigrationState: () => {
            const interval = setInterval(() => {
              console.log('Checking migration state ...');
              this.getMigrationState()
                .then((state) => {
                  if (state) window.location.reload();
                })
                .catch(console.error);
            }, 3000);

            return () => clearInterval(interval);
          }
        }
      }
    );
  }

  private static getDefaultContext() {
    return {
      user: undefined,
      profile: undefined,
      error: undefined,
      profileError: undefined
    };
  }

  public getUser(): Observable<User> {
    return defer(() => {
      // console.log('🛂 Ask authentication checking');

      // Send check request
      this.service.send({ type: 'CHECK' });

      // Return observable of machine state
      return this.state$;
    }).pipe(
      skipWhile((state) => state.matches({ authenticated: 'checking' })),
      tap((state) => {
        if (state.context.user) {
          datadogRum.setUser({
            id:
              state.context.user.attributes?.sub ??
              state.context.user.signInUserSession.accessToken.payload.sub // Used with challenges responses because attributes aren't included
          });
        } else {
          datadogRum.removeUser();
        }
      }),
      map((state) => {
        return state.context.error ? throwError(new Error(state.context.error)) : state.context.user;
      }),
      take(1)
    );
  }

  public getUserAccessToken(): Observable<string> {
    return this.getUser().pipe(map((user) => user.signInUserSession.accessToken.jwtToken));
  }

  public getMigrationState() {
    return this._core
      .getModule('request')
      .authenticatedRequest<Profile>(`${apiConfig.default}/user`)
      .pipe(map((response) => response.data.podosmart?.migrated ?? false))
      .toPromise();
  }
}
