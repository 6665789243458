// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
// Import factories --------------------------------------------------------------------------------
import { RepositoryFactory } from '../factories/Repository.factory';
// Import configurations ---------------------------------------------------------------------------
import { saasConfig } from '@/config/saas.config';
// Import types ------------------------------------------------------------------------------------
import type { Observable } from 'rxjs';
import type { AxiosResponse, AxiosRequestConfig } from 'axios';
import type { WorkspaceEntity } from './workspaces.repository';
// -------------------------------------------------------------------------------------------------

export interface ISaasPlan {
  id: string;
  name: string;
  periodUnit: string;
}

export interface ISaasSubscription {
  id: string;
  status: string;
  plan: ISaasPlan;
}

/**
 * Saas repository
 */
@injectable()
export class SaasRepository extends RepositoryFactory {
  /**
   * Fetch saas
   */
  fetchSubscription(
    currentWorkspaceCuid: WorkspaceEntity['cuid'],
    config?: AxiosRequestConfig
  ): Observable<AxiosResponse<ISaasSubscription>> {
    return this._requestModule.authenticatedRequest<ISaasSubscription>(
      `${saasConfig.default}/subscriptions/findOneByApplicationWorkspaceId/${currentWorkspaceCuid}`,
      config
    );
  }
}
