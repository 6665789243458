// Import vendors ----------------------------------------------------------------------------------
import { computed } from '@vue/composition-api';
// Import type -------------------------------------------------------------------------------------
import type { Ref } from '@vue/composition-api';
// Export / declare types --------------------------------------------------------------------------
export enum Library {
  Light = 'light',
  Regular = 'regular',
  Solid = 'solid',
  Duotone = 'duotone',
  Brands = 'brands',
  Kit = 'kit'
}
// -------------------------------------------------------------------------------------------------

export const iconsByAnalysisScenario: any = {
  walking_raw: {
    icon: 'analysis-walking',
    library: 'kit'
  },
  walking_embedded: {
    icon: 'analysis-walking',
    library: 'kit'
  },
  running_raw: {
    icon: 'analysis-running',
    library: 'kit'
  },
  running_embedded: {
    icon: 'analysis-running',
    library: 'kit'
  },
  jumping_CMJ_raw: {
    icon: 'analysis-jumping-cmj',
    library: 'kit'
  },
  jumping_single_hop_raw: {
    icon: 'analysis-jumping-single-hop',
    library: 'kit'
  },
  jumping_side_hop_raw: {
    icon: 'analysis-jumping-side-hop',
    library: 'kit'
  },
  jumping_triple_hop_raw: {
    icon: 'analysis-jumping-triple-hop',
    library: 'kit'
  },
  unknow: {
    icon: 'question',
    library: 'regular'
  }
};
// export function validateIconName(icon: string) {
//   return /^[a-z]+(?:-[a-z]+)*$/.test(icon);
// }

/**
 * Get font-awesome library
 */
export function getFaLibrary(library?: Library) {
  switch (library) {
    case Library.Light:
      return 'fal';
    case Library.Solid:
      return 'fas';
    case Library.Duotone:
      return 'fad';
    case Library.Brands:
      return 'fab';
    case Library.Kit:
      return 'fak';
    default:
      return 'far';
  }
}

/**
 * Use an icon
 */
export function useIcon(icon: Ref<string>, library?: Ref<Library>) {
  // Validate icon name
  // if (!validateIconName(icon.value)) throw new Error('Wrong icon format');
  // Create class
  const iconClass = computed(() => {
    return `${getFaLibrary(library?.value)} fa-${icon.value} fa-fw`;
  });

  return {
    iconClass
  };
}

/**
 * Get icon by scenario
 */
export function getIconByScenario(scenario: string) {
  return iconsByAnalysisScenario[scenario] ?? iconsByAnalysisScenario.unknow;
}
