// Import vendors ----------------------------------------------------------------------------------
import { Ability } from '@casl/ability';
import {
  AbilityAction,
  AbilitySubject
} from '@digitsole/blackburn-entities/dist/entities/ability/types/ability.enums';
// Import types ------------------------------------------------------------------------------------
import type { AbilityClass } from '@casl/ability';
// Export / declare types --------------------------------------------------------------------------
export type Abilities = [AbilityAction, AbilitySubject];
// -------------------------------------------------------------------------------------------------

export type AppAbility = Ability<Abilities>;
export const AppAbility = Ability as AbilityClass<AppAbility>;
