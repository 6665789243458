
























































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useService } from '@xstate/vue';
import { compact } from 'lodash';
// Import utils ------------------------------------------------------------------------------------
import { usePodocore } from '@/plugins/podocore';
import { useRoute } from '@/utils/router.utils';
import { useCDN } from '@/utils/cdn.utils';
import { useVersion } from '@/utils/version.utils';
// Import configurations ---------------------------------------------------------------------------
import { versionConfig } from '@/config/version.config';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
// -------------------------------------------------------------------------------------------------
export default defineComponent({
  name: 'Loader',
  components: { AlertError },
  setup() {
    const { currentVersion: version } = useVersion();

    const { isReady: routeIsReady } = useRoute();

    const stage = process.env['BLACKBURN__STAGE'];

    const { state } = useService(usePodocore().service);

    const { cdn } = useCDN();

    const newVersion = ref<null | string>(null);

    const currentVersion = ref(
      compact([
        version.value,
        stage && stage !== 'production' ? stage : undefined,
        versionConfig.default
      ]).join('-')
    );

    const isReady = computed(() => {
      if (routeIsReady.value && state.value.matches('ready')) {
        console.log('🎉 App is ready');
        return true;
      }

      return false;
    });

    const isCancelled = computed(() => state.value.matches('cancelled'));
    const isFatal = computed(() => state.value.matches('fatal'));

    const progress = computed(() => {
      if (state.value.matches({ bootstrap: 'checkingMaintenance' })) {
        return 5;
      } else if (state.value.matches({ bootstrap: 'fetchingConfig' })) {
        return 10;
      } else if (state.value.matches({ bootstrap: 'checkingVersion' })) {
        return 20;
      } else if (state.value.matches({ bootstrap: 'checkingAuthentication' })) {
        return 30;
      } else if (state.value.matches({ bootstrap: 'fetchingProfile' })) {
        return 40;
      } else if (state.value.matches({ bootstrap: 'fetchingDoctor' })) {
        return 60;
      } else if (state.value.matches({ bootstrap: 'fetchingWorkspaces' })) {
        return 70;
      } else if (state.value.matches({ bootstrap: 'fetchingAbilities' })) {
        return 80;
      } else if (state.value.matches({ bootstrap: 'fetchingSaas' })) {
        return 90;
      } else if (state.value.matches({ bootstrap: 'connecting' })) {
        return 95;
      } else if (state.value.matches('ready')) {
        return 100;
      }

      return 0;
    });

    watch(isCancelled, () => {
      if ((state.value as any).context.cancelReason === 'version') {
        newVersion.value = (state.value as any).children['version'].children.get(
          'module'
        ).state.context.newVersion;
      }
    });

    function reload() {
      window.location.reload();
    }

    function reloadApp(eraseStorage = false) {
      if (eraseStorage) window.localStorage.clear();
      reload();
    }

    return {
      // Values
      state,
      progress,
      currentVersion,
      newVersion,
      // Methods
      cdn,
      reloadApp,
      reload,
      // Flags
      isReady,
      isCancelled,
      isFatal
    };
  }
});
