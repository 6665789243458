// Import vendors ----------------------------------------------------------------------------------
import { actions, assign, createMachine, DoneInvokeEvent, sendParent } from 'xstate';
import { Auth } from '@aws-amplify/auth';
import { merge } from 'lodash';
// Import IoC --------------------------------------------------------------------------------------
import { container, Podocore, TOKENS } from '@/plugins/podocore';
// Import types ------------------------------------------------------------------------------------
import type { AnyEventObject } from 'xstate';
import type { AwsFriendlyError, User } from '@/plugins/podocore/modules/auth/auth.module';
// Import utils ------------------------------------------------------------------------------------
import { useAnalytics } from '@/utils/analytics.utils';
// -------------------------------------------------------------------------------------------------

/**
 * Analytics
 */
const { trackSuccess } = useAnalytics();

/**
 * Sign in machine
 */
export const signInMachine = createMachine(
  {
    id: 'signIn',
    context: {
      user: undefined,
      profile: undefined,
      credentials: undefined,
      signingInError: undefined,
      confirmingError: undefined,
      challengeSoftwareTokenMfaError: undefined,
      challengeNewPasswordRequiredError: undefined
    },
    initial: 'idle',
    states: {
      idle: {
        entry: assign({
          user(_) {
            return undefined;
          },
          profile(_) {
            return undefined;
          },
          credentials(_) {
            return undefined;
          }
        }),
        on: {
          SIGN_IN: '#signIn.signingIn'
        }
      },
      signingIn: {
        entry: assign({
          credentials(context: any, { data }: AnyEventObject) {
            return context.credentials ?? data;
          }
        }),
        initial: 'processing',
        states: {
          processing: {
            invoke: {
              src: 'signIn',
              onDone: [
                // Friendly error "UserNotConfirmedException"
                {
                  cond(_: any, event: DoneInvokeEvent<User | AwsFriendlyError>) {
                    return 'code' in event.data && event.data.code === 'UserNotConfirmedException';
                  },
                  target: '#signIn.confirming'
                },
                // Challenge "SOFTWARE_TOKEN_MFA"
                {
                  cond(_: any, event: DoneInvokeEvent<User | AwsFriendlyError>) {
                    return 'challengeName' in event.data && event.data.challengeName === 'SOFTWARE_TOKEN_MFA';
                  },
                  target: '#signIn.challenged.softwareTokenMfa'
                },
                // Challenge "NEW_PASSWORD_REQUIRED"
                {
                  cond(_: any, event: DoneInvokeEvent<User | AwsFriendlyError>) {
                    return (
                      'challengeName' in event.data && event.data.challengeName === 'NEW_PASSWORD_REQUIRED'
                    );
                  },
                  target: '#signIn.challenged.newPasswordRequired'
                },
                // ⚠ Challenge unknown
                {
                  cond(_: any, event: DoneInvokeEvent<User | AwsFriendlyError>) {
                    return 'challengeName' in event.data && event.data.challengeName;
                  },
                  target: '#signIn.challenged.unknown'
                },
                // No challenge or friendly error
                {
                  target: '#signIn.success'
                }
              ].map((cond, index) => {
                // Skip first friendly error, user return is not usable
                if (index === 0) return cond;
                // Return actions merged with user assignment
                return merge(cond, {
                  actions: assign({
                    user(_, { data }: AnyEventObject) {
                      return data;
                    }
                  })
                }) as any;
              }),
              onError: {
                actions: assign({
                  signingInError(_, { data }) {
                    return data;
                  },
                  credentials(_) {
                    return undefined;
                  }
                }),
                target: 'failure'
              }
            }
          },
          failure: {
            exit: assign({
              signingInError(_) {
                return undefined;
              }
            }),
            on: {
              SIGN_IN: '#signIn.signingIn',
              RESET: '#signIn.idle'
            }
          }
        }
      },
      confirming: {
        entry: [
          () => {
            console.log('✋ Waiting account confirmation');

            const bus = container.get<Podocore>(TOKENS.Podocore).getModule('bus');
            bus.publish(bus.events.routeBypass({ name: 'auth--sign-in' }));
          }
        ],
        initial: 'idle',
        states: {
          idle: {
            on: {
              CONFIRM: 'processing'
            }
          },
          processing: {
            invoke: {
              src: 'confirmSignUp',
              onDone: '#signIn.signingIn',
              onError: {
                actions: assign({
                  confirmingError(_, { data }) {
                    return data;
                  }
                }),
                target: 'failure'
              }
            }
          },
          failure: {
            exit: assign({
              confirmingError(_) {
                return undefined;
              }
            }),
            on: {
              CONFIRM: 'processing',
              RESET: '#signIn.idle'
            }
          }
        }
      },
      challenged: {
        states: {
          unknown: {
            initial: 'failure',
            states: {
              failure: {}
            }
          },
          softwareTokenMfa: {
            initial: 'idle',
            states: {
              idle: {
                on: {
                  RESOLVE_CHALLENGE_SOFTWARE_TOKEN_MFA: 'processing'
                }
              },
              processing: {
                invoke: {
                  src: 'resolveChallengeSoftwareTokenMfa',
                  onDone: {
                    actions: assign({
                      user(context: any, { data }) {
                        // Add attributes not provided when using MFA
                        return merge(context.user, {
                          // eslint-disable-next-line unicorn/no-array-reduce
                          attributes: data.reduce((accumulator: any, { Name, Value }: any) => {
                            accumulator[Name] = Value;
                            return accumulator;
                          }, {})
                        });
                      }
                    }),
                    target: '#signIn.success'
                  },
                  onError: {
                    actions: assign({
                      challengeSoftwareTokenMfaError(_, { data }) {
                        return data;
                      }
                    }),
                    target: 'failure'
                  }
                }
              },
              failure: {
                exit: assign({
                  challengeSoftwareTokenMfaError(_) {
                    return undefined;
                  }
                }),
                on: {
                  RESOLVE_CHALLENGE_SOFTWARE_TOKEN_MFA: 'processing'
                }
              }
            }
          },
          newPasswordRequired: {
            initial: 'idle',
            states: {
              idle: {
                on: {
                  RESOLVE_CHALLENGE_NEW_PASSWORD_REQUIRED: 'processing'
                }
              },
              processing: {
                invoke: {
                  src: 'resolveChallengeNewPasswordRequired',
                  onDone: '#signIn.success',
                  onError: {
                    actions: assign({
                      challengeNewPasswordRequiredError(_, { data }) {
                        return data;
                      }
                    }),
                    target: 'failure'
                  }
                }
              },
              failure: {
                exit: assign({
                  challengeNewPasswordRequiredError(_) {
                    return undefined;
                  }
                }),
                on: {
                  RESOLVE_CHALLENGE_NEW_PASSWORD_REQUIRED: 'processing'
                }
              }
            }
          }
        }
      },
      success: {
        entry: actions.pure((context: any) => {
          return sendParent({
            type: 'SIGNED_IN',
            data: {
              user: context.user,
              profile: context.profile
            }
          });
        }),
        on: {
          RESET: 'idle'
        }
      }
    }
  },
  {
    services: {
      async signIn(context: any, event: AnyEventObject) {
        return Auth.signIn(
          context.credentials?.username ?? event.data.username,
          context.credentials?.password ?? event.data.password
        ).catch((error) => {
          // Do not throw error for friendly errors
          if (error.code === 'UserNotConfirmedException') {
            return error;
          }

          throw error;
        });
      },
      async confirmSignUp(context: any, event: AnyEventObject) {
        trackSuccess('User ConfirmSignUp', { code: event.data.confirmationCode });
        return Auth.confirmSignUp(context.credentials.username, event.data.confirmationCode);
      },
      async resolveChallengeSoftwareTokenMfa(context: any, { data }) {
        await Auth.confirmSignIn(context.user, data.code, 'SOFTWARE_TOKEN_MFA');
        return Auth.userAttributes(context.user);
      },
      async resolveChallengeNewPasswordRequired(context: any, { data }) {
        return Auth.completeNewPassword(context.user, data.password);
      }
    }
  }
);
