// Import configurations ---------------------------------------------------------------------------
import { cdnConfig } from '@/config/cdn.config';
// Export / declare types --------------------------------------------------------------------------
export enum MediaType {
  Image = 'image',
  Documents = 'docs',
  Audio = 'audio',
  Other = 'other'
}
// -------------------------------------------------------------------------------------------------

/**
 * Get media from a CDN
 */
export function cdn(type: MediaType, filename: string, version?: string): string {
  let url = '';

  if (type === MediaType.Image) {
    url += `${cdnConfig.default}/medias/images/${filename}`;
  } else if (type === MediaType.Documents) {
    url += `${cdnConfig.default}/medias/docs/${filename}`;
  } else if (type === MediaType.Audio) {
    url += `${cdnConfig.default}/medias/audio/${filename}`;
  } else if (type === MediaType.Other) {
    url += `${cdnConfig.default}/${filename}`;
  } else {
    throw new Error(`Unknown type used in CDN helper : ${String(type)}`);
  }

  if (version) {
    url += `?versionId=${version}`;
  }

  return url;
}

/**
 * Use CDN (Content Network Delivery)
 */
export function useCDN() {
  return {
    cdn
  };
}
