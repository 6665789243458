// Import vendors ----------------------------------------------------------------------------------
import 'core-js/proposals/reflect-metadata';
import Vue from 'vue';
import { h } from '@vue/composition-api';
import { compact } from 'lodash';
import { datadogRum } from '@datadog/browser-rum';
// Import plugins ----------------------------------------------------------------------------------
import router from '@/plugins/router';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import '@/plugins/rx';
import '@/plugins/vee-validate';
import '@/plugins/highcharts';
import '@/plugins/vue-the-mask';
import '@/plugins/vue-flag-icon';
import '@/plugins/moment';
import '@/plugins/export';
import '@/plugins/vue-masonry-css';
import { createPodocore } from '@/plugins/podocore';
// Import configurations ---------------------------------------------------------------------------
import { versionConfig } from './config/version.config';
// Import components -------------------------------------------------------------------------------
import App from './App.vue';
import GlobalIcon from '@/components/globals/GlobalIcon.vue';
// Import utils ------------------------------------------------------------------------------------
import { useVersion } from '@/utils/version.utils';
// -------------------------------------------------------------------------------------------------

const { currentVersion } = useVersion();

const stage = process.env.BLACKBURN__STAGE;

// Initialize Amplitude
(window as any).amplitude
  ?.getInstance()
  .init(
    ['beta', 'production'].includes(String(stage))
      ? '0a494fbea2db70d3845d712f6bb05ca8'
      : '2011853ee238bb99171ffc99ca20469f'
  );

// Inject global components
Vue.component('GlobalIcon', GlobalIcon);

// Disable production tip in console
Vue.config.productionTip = false;

// Disable warnings
// Vue.config.silent = true;

Vue.component('font-awesome-icon', {
  props: {
    icon: [Array, String],
    size: [String, Number],
    fixedWidth: Boolean,
    rotation: [String, Number],
    flip: String
  },
  setup(properties) {
    console.warn(`Icon not provisioned for the framework`);
    console.warn(JSON.stringify(properties));
    return () => h('i', '🍕');
  }
});

// Instantiate Vue
new Vue({
  router,
  vuetify,
  i18n,
  setup() {
    createPodocore();

    if (process.env.BLACKBURN__DATADOG_RUM_ACTIVE) {
      if (!process.env.BLACKBURN__DATADOG_RUM_APP_ID) {
        console.warn('Missing "BLACKBURN__DATADOG_RUM_APP_ID" env variable');
        return;
      }
      if (!process.env.BLACKBURN__DATADOG_RUM_CLIENT_TOKEN) {
        console.warn('Missing "BLACKBURN__DATADOG_RUM_CLIENT_TOKEN" env variable');
        return;
      }

      datadogRum.init({
        applicationId: process.env.BLACKBURN__DATADOG_RUM_APP_ID,
        clientToken: process.env.BLACKBURN__DATADOG_RUM_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: 'blackburn',
        env: process.env.BLACKBURN__STAGE,
        version: compact([
          currentVersion.value,
          stage && stage !== 'production' ? stage : undefined,
          versionConfig.default
        ]).join('-'),
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask'
      });

      datadogRum.startSessionReplayRecording();
    }

    return () => h(App);
  }
}).$mount('#app');
