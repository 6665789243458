// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
// Import IoC --------------------------------------------------------------------------------------
import { lazyInject } from '../container';
import { TOKENS } from '../tokens';
// Import helpers ----------------------------------------------------------------------------------
import { simplifyName } from '../helpers/modules.helpers';
// Import types ------------------------------------------------------------------------------------
import type { RequestModule } from '../modules/request/request.module';
// -------------------------------------------------------------------------------------------------

/**
 * Repository factory
 */
@injectable()
export abstract class RepositoryFactory {
  @lazyInject(TOKENS.RequestModule) protected readonly _requestModule!: RequestModule;

  protected _name: string;

  public constructor() {
    this._name = simplifyName(this.constructor.name);
  }

  // Getters ---------------------------------------------------------------------------------------

  get name(): string {
    return this._name;
  }
}
