
















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, PropType } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------
export default defineComponent({
  name: 'AlertError',
  inheritAttrs: false,
  props: {
    error: {
      type: [Error, Object, String] as PropType<Error | { message: string; code: string }>,
      required: true
    },
    hideTitle: Boolean
  },
  setup(properties) {
    const sanitizedError = computed(() => {
      const r = {} as { type: string; message: string; hint?: string };
      if (typeof properties.error !== 'string' && 'message' in properties.error) {
        r.message = properties.error.message;
        r.hint = (properties.error as any).response?.data?.message;
        if ('code' in properties.error) {
          r.type = properties.error.code;
        } else {
          r.type = properties.error.name;
        }
      } else if (typeof properties.error === 'string') {
        return {
          type: 'Error',
          message: properties.error,
          hint: r.hint
        };
      }

      return r;
    });

    return {
      sanitizedError
    };
  }
});
