// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
// Import IoC --------------------------------------------------------------------------------------
import { lazyInject } from '../container';
import { TOKENS } from '../tokens';
// Import helpers ----------------------------------------------------------------------------------
import { simplifyName } from '../helpers/modules.helpers';
// Import types ------------------------------------------------------------------------------------
import type { Podocore } from '../';
// -------------------------------------------------------------------------------------------------

/**
 * Module factory
 */
@injectable()
export abstract class ModuleFactory {
  @lazyInject(TOKENS.Podocore) protected readonly _core!: Podocore;
  // TODO : Find type
  protected _name: string | any;

  public constructor() {
    this._name = simplifyName(this.constructor.name);
  }

  // Getters ---------------------------------------------------------------------------------------

  get name(): string {
    return this._name;
  }
}
