














// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, nextTick, ref } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useRoute } from '@/utils/router.utils';
import { useLayout } from '@/utils/layouts.utils';
import { usePodocoreModule } from '@/plugins/podocore';
// Import components -------------------------------------------------------------------------------
import Loader from '@/components/Loader.vue';
import ExtendedRouterView from '@/components/ExtendedRouterView.vue';
import DialogUpdate from './components/dialogs/DialogUpdate.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'App',
  components: { Loader, ExtendedRouterView, DialogUpdate },
  setup() {
    const { meta } = useRoute();
    // Get the layout related to the current route
    const layout = useLayout(meta);

    const inRouteTransition = ref(false);

    const busModule = usePodocoreModule('bus');

    busModule.useEventSubscriber(busModule.events.routeEnter, () => {
      inRouteTransition.value = true;
    });

    busModule.useEventSubscriber(busModule.events.routeLeave, () => {
      nextTick(() => {
        setTimeout(() => {
          inRouteTransition.value = false;
        }, 350);
      });
    });

    return {
      meta,
      layout,
      inRouteTransition
    };
  }
});
