// Import vendors ----------------------------------------------------------------------------------
import { inject, injectable } from 'inversify';
import { actions, assign, sendParent } from 'xstate';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { useObservable } from '@vueuse/rxjs';
import { computed } from '@vue/composition-api';
import { datadogRum } from '@datadog/browser-rum';
// Import factories --------------------------------------------------------------------------------
import { ModuleWithStateFactory } from '@/plugins/podocore/factories/ModuleWithState.factory';
// Import IoC --------------------------------------------------------------------------------------
import { TOKENS } from '../../tokens';
// Import helpers ----------------------------------------------------------------------------------
import { createModuleStateMachine } from '@/plugins/podocore/helpers/modules.helpers';
// Import repositories -----------------------------------------------------------------------------
import { SaasRepository } from '../../repositories/saas.repository';
// -------------------------------------------------------------------------------------------------

@injectable()
export class SaasModule extends ModuleWithStateFactory {
  constructor(@inject(TOKENS.SaasRepository) private readonly _saasRepository: SaasRepository) {
    super();

    const unsubscribeSet = new Set<() => void>();

    this.useSaas = this.useSaas.bind(this);

    this._machine = createModuleStateMachine(
      this._name,
      {
        initial: 'init',
        context: {
          fetchingError: undefined,
          currentSubscription: undefined
        },
        exit: () => {
          unsubscribeSet.forEach((unsubscribe) => unsubscribe());
          unsubscribeSet.clear();
        },
        states: {
          init: {
            always: {
              actions: () => {
                const bus = this._core.getModule('bus');

                unsubscribeSet.add(
                  bus.subscribe(bus.events.workspaceChanged, () => {
                    if (this.service.state.matches('fetching')) {
                      this.service.send({
                        type: 'CANCEL'
                      });
                    }
                    this.service.send({
                      type: 'FETCH'
                    });
                  })
                );
              },
              target: 'fetching'
            }
          },
          fetching: {
            on: {
              CANCEL: 'failure'
            },
            invoke: {
              src: 'fetchSubscription',
              onDone: [
                {
                  actions: assign({
                    currentSubscription: (_, { data }) => data
                  }),
                  target: 'success'
                }
              ],
              onError: [
                {
                  cond: (_, { data }) => [404, 403].includes(data.response.status),
                  target: 'success'
                },
                {
                  actions: assign({
                    fetchingError: (_, { data }) => data
                  }),
                  target: 'failure'
                }
              ]
            }
          },
          success: {
            entry: [
              actions.pure((context) => {
                datadogRum.setUser({
                  subscriptionId: context.currentSubscription?.id,
                  subscriptionPlanId: context.currentSubscription?.plan?.id
                });
                return sendParent({ type: 'FETCHED' });
              })
            ],
            exit: assign({
              currentSubscription: () => undefined
            }),
            on: {
              FETCH: 'fetching'
            }
          },
          failure: {
            entry: [actions.pure((context) => sendParent({ type: 'FATAL', data: context.fetchingError }))],
            exit: assign({
              fetchingError: () => undefined
            }),
            on: {
              FETCH: 'fetching'
            }
          }
        }
      },
      {
        services: {
          fetchSubscription: () =>
            of(this._core.getModuleService('workspaces').state.context.current.cuid)
              .pipe(
                switchMap((workspaceCuid) =>
                  this._saasRepository.fetchSubscription(workspaceCuid).pipe(map((response) => response.data))
                )
              )
              .toPromise()
        }
      }
    );
  }

  /**
   * useSaas
   */
  public useSaas() {
    const currentSubscription = useObservable(
      this.state$.pipe(map((value) => value.context.currentSubscription))
    );
    const currentPlanName = computed(() => {
      const planName: string | undefined = currentSubscription.value?.plan.id;

      if (planName) return 'Active';

      /* if (planName?.includes('-PROEXPERT-') || planName?.startsWith('dspro-pro_expert')) {
        return 'ProExpert';
      } else if (planName?.includes('-PROPLUS-') || planName?.startsWith('dspro-pro_plus')) {
        return 'Pro+';
      } else if (planName?.includes('-PRO-') || planName?.startsWith('dspro-pro')) {
        return 'Pro';
      } */
      return 'Demo';
    });
    return { currentSubscription, currentPlanName };
  }
}
