import Vue from 'vue';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';

import { container, createPodocore, Podocore, TOKENS } from '@/plugins/podocore';
import { h, provide, readonly } from '@vue/composition-api';

import type { PropType } from '@vue/composition-api';
import type { Entity } from '@/plugins/podocore/helpers/repositories.helper';
import type { Patient } from '@/plugins/podocore/repositories/patients.repository';

const bus = container.get<Podocore>(TOKENS.Podocore).getModule('bus');

Vue.component('export-frame', {
  props: {
    patient: {
      type: Object as PropType<Entity<Patient>>,
      required: true
    }
  },
  data() {
    return {
      exportApp: null
    };
  },
  render(h) {
    return h('iframe', {
      on: {
        load: (this as any).renderChildren
      },
      class: 'export-iframe',
      ref: 'export-iframe'
    });
  },
  beforeDestroy() {
    (this as any).exportApp.$destroy();
  },
  beforeUpdate() {
    // Freezing to prevent unnessessary reactifiation of vNodes
    // TODO : rearm
    // (this as any).exportApp.children = Object.freeze(this.$slots.default);
    this.$nextTick(() => {
      if ((this as any).exportApp) (this as any).exportApp.children = Object.freeze(this.$slots.default);
    });
  },
  methods: {
    renderChildren(t: any) {
      this.$nextTick(() => {
        // Avoid instance duplication
        if ((this as any).exportApp) return;

        const _this = this;

        const iframe = this.$refs['export-iframe'] as HTMLIFrameElement;
        const children = _this.$slots.default;
        const head = (_this.$el as any).contentDocument.head;
        const body = (_this.$el as any).contentDocument.body;
        const sheets = document.styleSheets;

        // DPI detector
        if (!document.getElementById('export-dpi-sensor')) {
          const dpiElement = document.createElement('div');
          dpiElement.id = 'export-dpi-sensor';
          dpiElement.style.height = '1in';
          dpiElement.style.width = '1in';
          dpiElement.style.position = 'absolute';
          dpiElement.style.zIndex = '-100000';
          dpiElement.style.top = '0';
          document.body.appendChild(dpiElement);
        }

        // App anchor
        const element = document.createElement('div');
        body.appendChild(element);

        // Create app instance
        (this as any).exportApp = new Vue({
          name: 'exportApp',
          // Freezing to prevent unnessessary reactifiation of vNodes
          data: {
            children: Object.freeze(children),
            timeout: <any>null
          },
          methods: {
            async print() {
              // _this.$pdc.bus!.emitDebug('export:pending:set', true);
              bus.publish(bus.events.analysisExportPrintSetIsPending({ value: true }));

              // Trick to avoid UI freeze
              await new Promise<void>((resolve) => {
                setTimeout(() => {
                  iframe.contentWindow!.print();
                  resolve();
                });
              });

              // _this.$pdc.bus!.emitDebug('export:pending:set', false);
              bus.publish(bus.events.analysisExportPrintSetIsPending({ value: false }));
            },
            clearCss() {
              [...body.querySelectorAll("[rel='stylesheet']")].forEach((element_) => {
                element_.remove();
              });

              [...head.getElementsByTagName('style')].forEach((style) => {
                style.remove();
              });
            },
            injectCss() {
              [...document.getElementsByTagName('style')].forEach((style) => {
                const _style = document.createElement('style');
                _style.innerHTML = style.innerHTML;
                head.appendChild(_style);
              });

              for (const { href } of sheets) {
                if (!href) continue;
                const link = document.createElement('link');
                link.href = href;
                link.rel = 'stylesheet';
                body.appendChild(link);
              }

              body.style.backgroundColor = 'white';
            },
            reloadCss(event: MessageEvent) {
              if (typeof event.data === 'string' && event.data.startsWith('webpackHotUpdate')) {
                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  // _this.$pdc.bus!.emitDebug('export:pending:set', true);
                  bus.publish(bus.events.analysisExportPrintSetIsPending({ value: true }));
                  (this as any).clearCss();
                  (this as any).injectCss();
                  // _this.$pdc.bus!.emitDebug('export:pending:set', false);
                  bus.publish(bus.events.analysisExportPrintSetIsPending({ value: false }));
                }, 1000);
              }
            }
          },
          created() {
            // Inject style
            (this as any).injectCss();

            this.$nextTick(() => {
              // _this.$pdc.bus!.emitDebug('export:pending:set', false);
              bus.publish(bus.events.analysisExportPrintSetIsPending({ value: false }));
            });

            if (module.hot) {
              window.addEventListener('message', (this as any).reloadCss);
            }
          },
          beforeDestroy() {
            if (module.hot) {
              window.removeEventListener('message', (this as any).reloadCss);
            }
          },
          setup(_, { root }) {
            createPodocore();

            provide('patient', readonly(_this.patient));

            return () => h('div', (root as any).children);
          },
          // store,
          vuetify,
          i18n
        });

        (this as any).exportApp.$mount(element); // Mount into iframe/ Cache instance for later updates
      });
    }
  }
});
