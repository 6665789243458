// Import types ------------------------------------------------------------------------------------
import type { ComputedRef } from '@vue/composition-api';
import type { CampaignQueryParameters } from '@/utils/router.utils';
// Export / declare types --------------------------------------------------------------------------
type CampaignQueryParametersEvent = {
  source?: string;
  medium?: string;
  campaign?: string;
  term?: string;
  content?: string;
};
// -------------------------------------------------------------------------------------------------

export function trackSuccess(type: string, data?: any) {
  (window as any).amplitude.logEvent(type, {
    status: 'success',
    error: null,
    data
  });
}

function trackFailure(type: string, error: Error | string, data?: any) {
  (window as any).amplitude.logEvent(type, {
    status: 'error',
    error: error instanceof Error ? error.message || error.stack : error,
    data
  });
}

/**
 * Convert parameters of a campaign query into an event payload
 */
export function campaignQueryParametersToEvent(
  campaignQuery: ComputedRef<CampaignQueryParameters>
): CampaignQueryParametersEvent {
  const result: CampaignQueryParametersEvent = {};

  for (const key in campaignQuery.value) {
    if ({}.hasOwnProperty.call(campaignQuery.value, key)) {
      result[key.split('_')[1] as keyof CampaignQueryParametersEvent] =
        campaignQuery.value[key as keyof CampaignQueryParameters];
    }
  }

  return result;
}

/**
 * Use analytics
 */
export function useAnalytics() {
  return { trackSuccess, trackFailure, campaignQueryParametersToEvent };
}
