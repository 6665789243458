// Import vendors ----------------------------------------------------------------------------------
import { inject, injectable } from 'inversify';
import { merge } from 'lodash';
import { assign, sendParent, actions } from 'xstate';
// Import IoC --------------------------------------------------------------------------------------
import { TOKENS } from '../../tokens';
// Import factories --------------------------------------------------------------------------------
import { ModuleWithStateFactory } from '../../factories/ModuleWithState.factory';
// Import helpers ----------------------------------------------------------------------------------
import { createModuleStateMachine } from '../../helpers/modules.helpers';
// Import repositories -----------------------------------------------------------------------------
import { MaintenanceRepository } from '../../repositories/maintenance.repository';
// Import types ------------------------------------------------------------------------------------
import type { Maintenance } from '../../repositories/maintenance.repository';
import type { DoneInvokeEvent, InvokeConfig, AnyEventObject, StatesConfig } from 'xstate';
// Export / declare types --------------------------------------------------------------------------
interface ModuleStateMachineContext {
  current: Maintenance | undefined;
  incoming: Maintenance | undefined;
}
// -------------------------------------------------------------------------------------------------

// inactive : no current or incoming
//    fetch each 5 minutes
//      if (incoming) -> incoming
//      if (current) -> current
// incoming
//    fetch each 2 minutes
//      if (no) -> inactive
//      if (current) -> current
// active
//    fetch each 30 secs
//    create dynamic after (max 30 sec)
//      if (no) -> inactive
//      if (incoming) -> incoming

/**
 * Maintenance module
 */
@injectable()
export class MaintenanceModule extends ModuleWithStateFactory {
  constructor(
    @inject(TOKENS.MaintenanceRepository)
    public readonly maintenanceRepository: MaintenanceRepository
  ) {
    super();

    this._machine = createModuleStateMachine(
      this._name,
      {
        context: {
          current: undefined,
          incoming: undefined,
          remainsBeforeBypass: 5
        },
        initial: 'init',
        states: {
          init: {
            invoke: merge(this.getCommonInvoke(), {
              onError: {
                actions: assign({
                  remainsBeforeBypass(context: any) {
                    return context.remainsBeforeBypass - 1;
                  }
                }),
                target: 'failure'
              }
            })
          },

          inactive: merge(this.getCommonStateNode(300000), {
            entry: [
              assign({
                incoming: () => undefined,
                current: () => undefined
              }),
              actions.pure((context) => {
                console.log('✅ No current or incoming maintenance');
                return sendParent({ type: 'INACTIVE', data: context });
              })
            ]
          }),
          incoming: merge(this.getCommonStateNode(120000), {
            entry: [
              assign({
                incoming: (context, event: DoneInvokeEvent<Maintenance>) => event.data,
                current: () => undefined
              }),
              actions.pure((context) => {
                console.log('👀 Incoming maintenance');
                return sendParent({ type: 'INCOMING', data: context });
              })
            ]
          }),
          active: merge(this.getCommonStateNode(30000), {
            entry: [
              assign({
                incoming: () => undefined,
                current: (context, event: DoneInvokeEvent<Maintenance>) => event.data
              }),
              actions.pure((context) => {
                console.log('🚨 Maintenance');
                return sendParent({ type: 'ACTIVE', data: context });
              })
            ]
          }),

          failure: {
            after: {
              5000: [
                {
                  cond(context) {
                    return context.remainsBeforeBypass > 0;
                  },
                  target: 'init'
                },
                {
                  target: '#module.inactive'
                }
              ]
            }
          }
        }
      },
      {
        services: {
          fetchMaintenances: this.maintenanceRepository.fetchMaintenances
        }
      }
    );
  }

  getCurrentMaintenance(maintenances: Maintenance[]): Maintenance | undefined {
    return maintenances.find((maintenance) => maintenance.isActive());
  }

  getIncomingMaintenance(maintenances: Maintenance[]): Maintenance | undefined {
    return maintenances.find((maintenance) => maintenance.isIncoming());
  }

  private getCommonInvoke(): InvokeConfig<ModuleStateMachineContext, AnyEventObject> {
    return {
      src: 'fetchMaintenances',
      onDone: [
        {
          cond: (context, event) => Boolean(this.getCurrentMaintenance(event.data)),
          target: '#module.active',
          actions: [
            assign({
              current: (context, event: DoneInvokeEvent<Maintenance>) => event.data
            }),
            actions.pure((context) => {
              return sendParent({ type: 'ACTIVE', data: context });
            })
          ]
        },
        {
          cond: (context, event) => Boolean(this.getIncomingMaintenance(event.data)),
          target: '#module.incoming',
          actions: [
            assign({
              current: (context, event: DoneInvokeEvent<Maintenance>) => event.data
            }),
            actions.pure((context) => {
              return sendParent({ type: 'INCOMING', data: context });
            })
          ]
        },
        {
          target: '#module.inactive',
          actions: [
            assign({
              current: (context, event: DoneInvokeEvent<Maintenance>) => event.data
            }),
            actions.pure((context) => {
              return sendParent({ type: 'INACTIVE', data: context });
            })
          ]
        }
      ],
      onError: {
        target: 'idle'
      }
    };
  }

  private getCommonStateNode(
    delay: number
  ): StatesConfig<ModuleStateMachineContext, any, AnyEventObject>['node'] {
    return {
      initial: 'idle',
      states: {
        idle: {
          after: {
            [delay]: 'fetching'
          }
        },
        fetching: {
          invoke: this.getCommonInvoke()
        }
      }
    };
  }
}
