/**
 * The property doesn't respect its declared type
 */
export class ConfigTypeError extends Error {
  public readonly name: string = 'ConfigTypeError';

  public readonly key: string;

  public readonly expected: string;

  public readonly actual: string;

  public constructor(key: string, expected: string, actual: string) {
    super();

    this.key = key;
    this.expected = expected;
    this.actual = actual;

    this.message = `The value of the configuration key "${this.key}" has an invalid type. Expected a "${this.expected}", but got a "${this.actual}"`;
  }
}
