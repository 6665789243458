import Vue from 'vue';
import Highcharts from 'highcharts';

import HighchartsVue from 'highcharts-vue';

import highchartsMore from 'highcharts/highcharts-more';
import stock from 'highcharts/modules/stock';
import solidGauge from 'highcharts/modules/solid-gauge';
// -------------------------------------------------------------------------------------------------

highchartsMore(Highcharts);
stock(Highcharts);
solidGauge(Highcharts);

declare module 'vue/types/vue' {
  interface Vue {
    $hc: typeof Highcharts;
  }
}

Vue.use({
  install(vue) {
    vue.prototype.$hc = Highcharts;
  }
});

// eslint-disable-next-line import/no-named-as-default-member
Highcharts.setOptions({
  credits: {
    enabled: false
  },
  title: {
    text: 'Undefined',
    align: 'left',
    margin: 40,
    style: {
      fontFamily: 'Montserrat'
    }
  },
  chart: {
    style: {
      fontFamily: 'Montserrat'
    },
    backgroundColor: 'transparent'
  },
  // Number in bar
  plotOptions: {
    bar: {
      dataLabels: {
        style: {
          fontSize: '18px'
        }
      }
    }
  },
  exporting: {
    enabled: true,
    sourceWidth: 718,
    scale: 10,
    buttons: {
      contextButton: {
        symbolFill: '#f88',
        menuItems: ['printChart']
      }
    }
  },
  tooltip: {
    headerFormat: '<span style="font-size:15px;">{point.key}</span><br/>',
    borderWidth: 0,
    backgroundColor: 'white',
    padding: 15
  }
});

// Inject plugin
Vue.use(HighchartsVue);
