// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
import { isNil, last, merge } from 'lodash';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import SparkMD5 from 'spark-md5';
// Import factories --------------------------------------------------------------------------------
import { RepositoryFactory } from '../factories/Repository.factory';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
import { saasConfig } from '@/config/saas.config';
// Import types ------------------------------------------------------------------------------------
import { from, Observable } from 'rxjs';
import type { GetObservableGeneric } from '@/utils/types.utils';
import type { AxiosResponse, AxiosRequestConfig } from 'axios';
import type {
  WorkspaceEntity,
  WorkspaceEntityFilter
} from '@digitsole/blackburn-entities/dist/entities/workspace/workspace.entity';
import type { WorkspacesController } from '@digitsole/blackburn-api/dist/routes/workspaces/workspaces.controller';
import type { UpdateWorkspaceDto } from '@digitsole/blackburn-entities/dist/entities/workspace/dto/update-workspace.dto';
// Export types ------------------------------------------------------------------------------------
export type {
  WorkspaceEntity,
  WorkspaceEntityFilter
} from '@digitsole/blackburn-entities/dist/entities/workspace/workspace.entity';
// -------------------------------------------------------------------------------------------------

/**
 * Workspaces repository
 */
@injectable()
export class WorkspacesRepository extends RepositoryFactory {
  /**
   * Fetch SaaS meta
   */
  fetchSaasMeta(config?: AxiosRequestConfig): Observable<AxiosResponse> {
    return this._requestModule.authenticatedRequest(`${saasConfig.default}/meta`, config);
  }

  /**
   * Fetch workspaces
   */
  fetchWorkspaces(
    config?: AxiosRequestConfig
  ): Observable<AxiosResponse<GetObservableGeneric<ReturnType<WorkspacesController['readAll']>>>> {
    return this._requestModule.authenticatedRequest<
      GetObservableGeneric<ReturnType<WorkspacesController['readAll']>>
    >(`${apiConfig.default}/workspaces`, config);
  }

  /**
   * Create workspaces
   */
  createWorkspace(
    // createWorkspaceDto: CreateWorkspaceDto,
    config?: AxiosRequestConfig
  ): Observable<AxiosResponse<GetObservableGeneric<ReturnType<WorkspacesController['create']>>>> {
    return this._requestModule.authenticatedRequest<
      GetObservableGeneric<ReturnType<WorkspacesController['create']>>
    >(
      `${apiConfig.default}/workspaces`,
      merge({}, config, {
        method: 'POST'
        // data: createWorkspaceDto
      })
    );
  }

  /**
   * Patch workspaces
   */
  patchWorkspace(
    workspaceCuid: WorkspaceEntity['cuid'],
    updateWorkspaceDto: UpdateWorkspaceDto,
    config?: AxiosRequestConfig
  ): Observable<AxiosResponse<GetObservableGeneric<ReturnType<WorkspacesController['update']>>>> {
    return this._requestModule.authenticatedRequest<
      GetObservableGeneric<ReturnType<WorkspacesController['update']>>
    >(
      `${apiConfig.default}/workspaces/${workspaceCuid}`,
      merge({}, config, {
        method: 'PATCH',
        data: updateWorkspaceDto
      })
    );
  }

  /**
   * Patch workspace's logo (will take care of upload)
   */
  patchWorkspaceLogo(
    workspaceCuid: WorkspaceEntity['cuid'],
    data: { file: File },
    config?: AxiosRequestConfig
  ): Observable<
    AxiosResponse<GetObservableGeneric<ReturnType<WorkspacesController['updateWorkspaceLogo']>>>
  > {
    const extension = last(data.file.type.split('/'));
    const size = data.file.size;

    return from(this._calculHash(data.file)).pipe(
      switchMap((md5Checksum) =>
        this._requestModule
          .authenticatedRequest<
            GetObservableGeneric<ReturnType<WorkspacesController['updateWorkspaceLogo']>>
          >(
            `${apiConfig.default}/workspaces/${workspaceCuid}/logo`,
            merge({}, config, {
              method: 'POST',
              data: {
                extension,
                size,
                md5Checksum
              }
            })
          )
          .pipe(
            map(({ data }) => {
              if (!data || !('presignedS3UploadUrl' in data)) {
                throw new Error('missing presignedS3UploadUrl');
              }
              return data;
            }),
            switchMap((_data) =>
              this._requestModule.request(_data.presignedS3UploadUrl, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/octet-stream',
                  'Content-MD5': md5Checksum
                },
                data: data.file
              })
            ),
            // Add artificial delay to let cloud service enough time to process image
            delay(3000)
          )
      )
    );
  }

  /**
   * Delete workspace's logo
   */
  deleteWorkspaceLogo(
    workspaceCuid: WorkspaceEntity['cuid'],
    config?: AxiosRequestConfig
  ): Observable<
    AxiosResponse<GetObservableGeneric<ReturnType<WorkspacesController['removeWorkspaceLogo']>>>
  > {
    return this._requestModule.authenticatedRequest<
      GetObservableGeneric<ReturnType<WorkspacesController['removeWorkspaceLogo']>>
    >(
      `${apiConfig.default}/workspaces/${workspaceCuid}/logo`,
      merge({}, config, {
        method: 'DELETE'
      })
    );
  }

  /**
   * Create filter
   */
  createFilter(
    workspaceCuid: WorkspaceEntity['cuid'],
    filter: WorkspaceEntityFilter,
    config?: AxiosRequestConfig
  ): Observable<AxiosResponse<GetObservableGeneric<ReturnType<WorkspacesController['createFilter']>>>> {
    return this._requestModule.authenticatedRequest<
      GetObservableGeneric<ReturnType<WorkspacesController['createFilter']>>
    >(
      `${apiConfig.default}/workspaces/${workspaceCuid}/filters`,
      merge({}, config, {
        method: 'POST',
        data: filter
      })
    );
  }

  /**
   * Patch filter
   */
  patchFilter(
    workspaceCuid: WorkspaceEntity['cuid'],
    filterId: WorkspaceEntityFilter['cuid'],
    filter: WorkspaceEntityFilter,
    config?: AxiosRequestConfig
  ): Observable<AxiosResponse<GetObservableGeneric<ReturnType<WorkspacesController['updateFilter']>>>> {
    return this._requestModule.authenticatedRequest<
      GetObservableGeneric<ReturnType<WorkspacesController['updateFilter']>>
    >(
      `${apiConfig.default}/workspaces/${workspaceCuid}/filters/${filterId}`,
      merge({}, config, {
        method: 'PATCH',
        data: filter
      })
    );
  }

  /**
   * Delete filter
   */
  deleteFilter(
    workspaceCuid: WorkspaceEntity['cuid'],
    filterId: WorkspaceEntityFilter['cuid'],
    config?: AxiosRequestConfig
  ): Observable<AxiosResponse<GetObservableGeneric<ReturnType<WorkspacesController['deleteFilter']>>>> {
    return this._requestModule.authenticatedRequest<
      GetObservableGeneric<ReturnType<WorkspacesController['deleteFilter']>>
    >(
      `${apiConfig.default}/workspaces/${workspaceCuid}/filters/${filterId}`,
      merge({}, config, {
        method: 'DELETE'
      })
    );
  }

  private async _calculHash(file: File) {
    const bufferSize = Math.pow(1024, 2) * 1; // 1MB
    const reader = new FileReader();
    const hashAlgorithm = new SparkMD5();
    const totalParts = Math.ceil(file.size / bufferSize);

    let hashCurrentPart = 0;

    const hash: string = await new Promise((resolve) => {
      function processNextPart() {
        const start = hashCurrentPart * bufferSize;
        const end = Math.min(start + bufferSize, file.size);

        reader.readAsBinaryString(file.slice(start, end));
      }

      reader.onload = function (readerEvent) {
        if (isNil(readerEvent.target)) throw new Error('reader target must be defined');

        hashCurrentPart += 1;
        hashAlgorithm.appendBinary(readerEvent.target.result as any);
        if (hashCurrentPart < totalParts) {
          processNextPart();
        } else {
          resolve(window.btoa(hashAlgorithm.end(true)));
        }
      };

      processNextPart();
    });

    return hash;
  }
}
