// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
import { from } from 'rxjs';
// Import factories --------------------------------------------------------------------------------
import { ModuleFactory } from '@/plugins/podocore/factories/Module.factory';
// Export / declare types --------------------------------------------------------------------------
import type { Observable, ObservableInput } from 'rxjs';
// -------------------------------------------------------------------------------------------------

/**
 * Module with state factory
 */
@injectable()
export abstract class ModuleWithStateFactory extends ModuleFactory {
  protected _machine: any;

  get machine() {
    return this._machine;
  }

  get service() {
    return this._core.getModuleService(this._name);
  }

  get state$(): Observable<any> {
    return from(this.service as ObservableInput<any>);
  }
}
