import { compact } from 'lodash';

export interface IOptions {
  prefix?: string;
  package?: string;
}

// eslint-disable-next-line security/detect-unsafe-regex
const PACKAGE_REGEX: RegExp = /^[a-z]((:?\-)?\w)*(\/[a-z]((:?\-)?\w)*)*$/;

/**
 * Convert key to env name
 */
export function convertKeyToEnvName(key: string, options?: IOptions): string {
  // Split key into fragments
  const k = compact(key.split('.'));

  const sanitizedOptions: Partial<IOptions> = {
    prefix: options?.prefix?.trim(),
    package: options?.package?.trim()
  };

  let m: string[] = [];
  let p: string[] = [];

  if (sanitizedOptions.prefix?.length) {
    m = [sanitizedOptions.prefix];
  }

  if (sanitizedOptions.package?.length) {
    if (!PACKAGE_REGEX.test(sanitizedOptions.package)) throw new Error('Package is invalid');
    p = sanitizedOptions.package.split('/');
  }

  // Transform items to uppercase
  const [mUp, pUp, kUp] = [m, p, k].map((arr) => arr.map((item) => item.trim().toUpperCase()).join('_'));

  // Join them
  return compact([mUp, pUp, kUp]).join('__');
}
