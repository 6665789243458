// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, shallowRef } from '@vue/composition-api';
// Import types ------------------------------------------------------------------------------------
import type { Component } from 'vue';
import type { ComputedRef } from '@vue/composition-api';
import type { RouteMeta } from 'vue-router';
// Export / declare types --------------------------------------------------------------------------
export enum Layout {
  Default = 'Default',
  Auth = 'Auth',
  Unknown = 'Unknown'
}
// -------------------------------------------------------------------------------------------------

/**
 * Get layout component name
 */
export function getLayoutComponentName(layout: Layout): string {
  return `Layout${layout}`;
}

/**
 * Get layout component
 */
export function getLayoutComponent(layout: Layout): Component {
  return defineComponent(async () => {
    switch (layout) {
      case Layout.Auth:
        return import('@/layouts/auth/LayoutAuth.vue');
      case Layout.Unknown:
        return import('@/layouts/unknown/LayoutUnknown.vue');
      case Layout.Default:
      default:
        return import('@/layouts/default/LayoutDefault.vue');
    }
  });
}

/**
 * Layout factory
 */
export function layoutFactory(
  parameters: { layout: Layout },
  options?: Parameters<typeof defineComponent>[0]
): Component {
  return defineComponent({
    name: getLayoutComponentName(parameters.layout),
    ...options
  }) as any;
}

/**
 * Get layout component based on the route configuration
 */
export function useLayout(meta: ComputedRef<RouteMeta>): ComputedRef<Component> {
  // If same layout is used between routes, cache avoid transition triggering
  const cachedLayout = shallowRef<[string, Component] | null>(null);
  // Current layout component
  return computed(() => {
    // Get layout name from route meta
    const layoutName = meta.value.layout ?? Layout.Default;
    // Save component in cache
    if (!cachedLayout.value || cachedLayout.value[0] !== layoutName) {
      console.log('🔳 Switch layout');
      cachedLayout.value = [layoutName, getLayoutComponent(layoutName)];
    }

    // Return layout component from cache
    return cachedLayout.value[1];
  });
}
