import { Subject, BehaviorSubject, Observable, of, from } from 'rxjs';
import { switchMap, share, tap, map, catchError, concatMap } from 'rxjs/operators';
import { retryBackoff } from 'backoff-rxjs';
import WebSocketConstructor from 'ws';

import type { Subscription } from 'rxjs';
import type { IMessageError, IMessageSuccess, IRequest, IState } from './types';

// Detect if the script is used in node or in a browser and choose the specific websocket constructor
const WebSocketCtor: typeof WebSocketConstructor | typeof WebSocket =
  typeof window === 'undefined' ? WebSocketConstructor : WebSocket;

export const NORMAL_CLOSURE_MESSAGE: string = 'Normal closure';

/**
 * Websocket client
 */
export class WebsocketClient extends Subject<never> {
  private _url: string;
  private _token: string;
  private _debug: boolean;

  private _request$: Subject<IRequest>;
  private _state$: BehaviorSubject<IState>;

  private _tokenRetriever$?: Observable<string>;

  /**
   * @param {string} url
   */
  public constructor(url: string, token: string, options?: { debug?: true; reconnectInterval?: number }) {
    super();

    this._url = url;
    this._token = token;
    this._debug = options?.debug ?? false;

    this._request$ = new Subject<IRequest>();

    this._state$ = new BehaviorSubject<IState>({
      isConnected: false,
      isChallenged: false,
      isAuthenticated: false
    });
  }

  public createClient$(token?: Observable<string>): Observable<IMessageSuccess | IMessageError> {
    this._log('create client');

    if (token) this._tokenRetriever$ = token;

    return this._createSocket();
  }

  /**
   * Create socket
   */
  private _createSocket(): Observable<IMessageSuccess | IMessageError> {
    // Create socket
    const _socket$ = new Observable<
      (request$: Subject<IRequest>) => Subject<IMessageSuccess | IMessageError>
    >((observer$) => {
      const messages$ = new Subject<IMessageSuccess | IMessageError>();

      const socket = new WebSocketCtor(this._url);

      let requestSubscription: Subscription;

      let isSocketClosed = false;
      let forcedClose = false;

      function getWebSocketResponses(request$: Subject<IRequest>): typeof messages$ {
        requestSubscription = request$.subscribe((data) => {
          socket.send(JSON.stringify(data));
        });
        return messages$;
      }

      socket.onopen = (): void => {
        if (forcedClose) {
          this._log('in closing state, kill freshly opened');
          isSocketClosed = true;
          socket.close();
        } else {
          this._log('opened');
          observer$.next(getWebSocketResponses);
        }
      };

      socket.onmessage = (message: { data: string }): void => {
        this._log('in message');

        const parsedMessage = JSON.parse(message.data);

        if (!('statusCode' in parsedMessage)) throw new Error('Message is invalid');

        messages$.next(JSON.parse(message.data) as IMessageSuccess | IMessageError);
      };

      socket.onerror = (event: { message: string | undefined }): void => {
        isSocketClosed = true;
        observer$.error(new Error(event.message));
      };

      socket.onclose = (event: { code: number; reason: string | undefined }): void => {
        // prevent observer.complete() being called after observer.error(...)
        if (isSocketClosed) return;

        isSocketClosed = true;

        if (forcedClose) {
          observer$.complete();
          messages$.complete();
        } else {
          observer$.error(new Error(event.code === 1000 ? NORMAL_CLOSURE_MESSAGE : event.reason));
        }
      };

      return () => {
        this._log('close');

        forcedClose = true;

        if (requestSubscription) requestSubscription.unsubscribe();

        if (!isSocketClosed) {
          isSocketClosed = true;
          socket.close();
        }
      };
    }).pipe(
      catchError((error: Error) => {
        const { message } = error;

        if (message === NORMAL_CLOSURE_MESSAGE) {
          this._log('permanently closed');
        }

        this._setState({
          isConnected: false,
          isChallenged: false,
          isAuthenticated: false
        });

        throw error;
      }),
      // Exponential retry
      retryBackoff({
        initialInterval: 1000,
        resetOnSuccess: true
      }),
      // Set state
      tap(() =>
        this._setState({
          isConnected: true,
          isChallenged: false,
          isAuthenticated: false
        })
      ),
      share()
    );

    // Create message canal
    const _messages$ = _socket$.pipe(
      // the observable produces a value once the websocket has been opened
      switchMap((getResponses) => {
        this._log('switch to messages canal');
        return getResponses(this._request$);
      }),
      share()
    );

    return _messages$.pipe(
      concatMap((message) => {
        // Waiting authentication challenge
        if (!this.state.isChallenged && !this.state.isAuthenticated) {
          return of(message).pipe(
            map((message) => {
              if (
                message.statusCode === 200 &&
                message.body.status === 'success' &&
                message.body.challenge === 'authentication'
              )
                return message;
              throw new Error("First message isn't a valid challenge");
            }),
            // Set state
            tap(() => {
              this._setState({ isChallenged: true });
              this._log('challenged');
            }),
            switchMap((message) => {
              if (this._tokenRetriever$)
                return this._tokenRetriever$.pipe(
                  map((token) => {
                    this._log('set token');
                    this._token = token;
                    return message;
                  })
                );
              return of(message);
            }),
            // Send authentication challenge
            tap(() => this._request$.next({ challenge: 'authentication', token: this._token }))
          );
        }
        // Waiting acknowledge response
        else if (this.state.isChallenged && !this.state.isAuthenticated) {
          return of(message).pipe(
            map((message) => {
              if (message.statusCode === 200 && message.body.status === 'success') return message;
              throw new Error('Authentication failed');
            }),
            // Set state
            tap(() => {
              this._setState({ isAuthenticated: true });
              this._log('authenticated');
            })
          );
        } else if (this.state.isChallenged && this.state.isAuthenticated) {
          return of(message);
        } else {
          throw new Error('Unknown state, cannot process');
        }
      }),
      catchError((error) => {
        console.error(error);

        this._setState({
          isChallenged: false,
          isAuthenticated: false
        });

        throw error;
      }),
      // Exponential retry
      retryBackoff({
        initialInterval: 1000
      })
    );
  }

  /**
   * Get the current state
   */
  public get state(): IState {
    return this._state$.getValue();
  }

  /**
   * Log debug
   * @param {string[]} params
   */
  private _log(...params: string[]): void {
    if (this._debug) console.log('[ws]', ...params);
  }

  /**
   * Set state
   * @param {Partial<IState>} newState
   */
  private _setState(newState: Partial<IState>): void {
    this._state$.next({
      ...this.state,
      ...newState
    });
  }
}

// ***********************

// const userId: string = 'ac752db9-81e5-4c0a-989e-ea96feef56bd';
// const url: string = `wss://rzzff0d6f0.execute-api.eu-central-1.amazonaws.com/sandbox?userId=${userId}`;

// const client: WebsocketClient = new WebsocketClient(url, { debug: true });

// client.subscribe({
//   next() {
//     console.log('CLIENT NEXT');
//   },
//   complete() {
//     console.log('CLIENT COMPLETE');
//   }
// });

// setTimeout(() => {
//   client.complete();
// }, 2000);
