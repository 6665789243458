





































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogUpdate',
  components: { CompositeDialog },
  setup() {
    const dialogId: DialogId = 'update';
    const rDialog = ref<any>(null);

    const { cdn } = useCDN();

    const versionService = usePodocoreModuleService('version');

    const isActive = computed(() => (versionService.state.value as any).matches('versionNotEqual'));

    onMounted(() => {
      watch(
        isActive,
        (value) => {
          if (value) rDialog.value.open();
        },
        { immediate: true }
      );
    });

    const newVersion = computed(() => (versionService.state.value as any).context.newVersion);

    const newVersionSplitted = computed(() => newVersion.value?.split('-'));

    function reload() {
      window.location.reload();
    }

    return {
      dialogId,
      rDialog,
      cdn,
      newVersion,
      newVersionSplitted,
      reload
    };
  }
});
