// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
import moment from 'moment';
import { map } from 'rxjs/operators';
// Import helpers ----------------------------------------------------------------------------------
import { Environment } from '@/helpers/environment.helper';
// Import factories --------------------------------------------------------------------------------
import { RepositoryFactory } from '../factories/Repository.factory';
// Export / declare types --------------------------------------------------------------------------
type MaintenanceType = 'blackout' | string;
type MaintenanceTarget = 'browser-app' | string;
// -------------------------------------------------------------------------------------------------

export interface MaintenanceRaw {
  type: MaintenanceType;
  start: Date;
  end: Date;
  env: Environment;
  delay: number;
  targets: MaintenanceTarget[];
}

export interface Maintenance extends Omit<MaintenanceRaw, 'start' | 'end'> {
  start: moment.Moment;
  end: moment.Moment;

  products?: string[];
  isActive(): boolean;

  isIncoming(): boolean;
}

// -------------------------------------------------------------------------------------------------

// Type = blackout for podosmart,  lock for DSPro
/* const fakeMaintenance: Maintenance = {
  type: 'lock',
  targets: ['browser-app'],
  env: Environment.Beta,
  start: new Date('2020-12-22T12:00:00.000Z'),
  end: new Date('2020-12-24T12:00:00.000Z'),
  delay: 8.64e7,
  // Add products list
  products: ["blackburn"]
}; */

/**
 * Maintenance repository
 */
@injectable()
export class MaintenanceRepository extends RepositoryFactory {
  constructor() {
    super();

    this.fetchMaintenances = this.fetchMaintenances.bind(this);
  }

  /**
   * Fetch maintenances
   */
  async fetchMaintenances(): Promise<Maintenance[]> {
    const nowDate = moment();

    return this._requestModule
      .request<Maintenance[]>('https://s3.eu-central-1.amazonaws.com/cdn.podosmart.tech/maintenances.json', {
        params: {
          timestamp: moment().unix()
        }
      })
      .pipe(
        map((response): Maintenance[] =>
          response.data
            .filter(
              (maintenance) =>
                maintenance.env === process.env.BLACKBURN__STAGE &&
                maintenance.type === 'lock' &&
                maintenance.products?.includes('blackburn')
            )
            .map((maintenance) => ({
              ...maintenance,
              start: moment(maintenance.start),
              end: moment(maintenance.end),
              isActive() {
                return nowDate.isBetween(maintenance.start, maintenance.end);
              },
              isIncoming() {
                const diff = moment(maintenance.start).diff(nowDate);
                // Default delay : a week
                return diff > 0 && diff <= (maintenance.delay ?? 4.32e8);
              }
            }))
        )
      )
      .toPromise();
  }
}
