import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  required,
  email,
  confirmed,
  is,
  is_not,
  regex,
  min,
  min_value,
  max,
  max_value,
  integer,
  oneOf,
  length
} from 'vee-validate/dist/rules';

import { phone } from 'phone';
import moment from 'moment';

import i18n from '@/plugins/i18n';
import { last } from 'lodash';

const REGEX_PASSWORD =
  /^((?=[A-Za-z0-9^$*.[\]{}()?"!@#%&-+/\\,><':;|_~`]*[A-Z])(?=[A-Za-z0-9^$*.[\]{}()?"!@#%&-+/\\,><':;|_~`\s]*[a-z])(?=[A-Za-z0-9^$*.[\]{}()?"!@#%&-+/\\,><':;|_~`\s]*[\^$*.[\]{}()?"!@#%&-+/\\,><':;|_~`])(?=[A-Za-z0-9^$*.[\]{}()?"!@#%&-+/\\,><':;|_~`\s]*\d).{7,})[A-Za-z0-9^$*.[\]{}()?"!@#%&-+/\\,><':;|_~`]$/;

extend('required', {
  ...required,
  message: (_, values) => i18n.t('commons.validations.required', values) as string
});

extend('email', {
  ...email,
  message: (_, values) => i18n.t('commons.validations.email', values) as string
});

extend('confirmed', confirmed);
extend('is', is);
extend('regex', regex);
extend('min', {
  ...min,
  message: (_, values) => i18n.t('commons.validations.min', values) as string
});
extend('min_value', {
  ...min_value,
  message: (_, values) => i18n.t('commons.validations.min_value', values) as string
});
extend('max', { ...max, message: (_, values) => i18n.t('commons.validations.max', values) as string });
extend('max_value', {
  ...max_value,
  message: (_, values) => i18n.t('commons.validations.max_value', values) as string
});
extend('integer', {
  ...integer,
  message: (_, values) => i18n.t('commons.validations.integer', values) as string
});
extend('oneOf', oneOf);
extend('length', {
  ...length,
  message: (_, values) => i18n.tc('commons.validations.length', values.length)!
});

extend('checkbox', {
  message: (_, values) => i18n.t('commons.validations.checkbox-required', values) as string,
  validate: (value: any, key?: any) => {
    if (Array.isArray(value) && key?.length) {
      return value.includes(key[0]);
    } else {
      return value;
    }
  },
  computesRequired: true
});

extend('date', {
  message: (_, values) => i18n.t('commons.validations.date-invalid', values) as string,
  validate: (value: string, dateFormat: any) => {
    return moment(value, dateFormat[0], true).isValid();
  }
});

extend('birthDate', {
  message: (_, values) => i18n.t('commons.validations.date-invalid', values) as string,
  validate: (value: string, dateFormat: any) => {
    const isValid = moment(value, dateFormat[0], true).isValid();
    if (isValid) {
      const before = moment(value, dateFormat[0]).diff(moment().subtract(120, 'years'), 'days');
      const now = moment(value, dateFormat[0]).diff(moment().subtract(7, 'years'), 'days');
      return Math.sign(before) >= 0 && Math.sign(now) < 0;
    }

    return false;
  }
});

extend('array_min', {
  message: (_, values) => i18n.t('commons.validations.array-max', values) as string,
  validate: (value: any, min) => {
    return value.length >= min;
  }
});

extend('array_max', {
  message: (_, values) => i18n.t('commons.validations.array-max', values) as string,
  validate: (value: any, max) => {
    return value.length <= max;
  }
});

extend('UsernameExistsException', {
  ...is_not,
  message: (_) => i18n.t('errors.UsernameExistsException') as string
});

extend('password', {
  validate: (value: string) => {
    return REGEX_PASSWORD.test(value);
  },
  message: (_) => i18n.t('errors.password-not-strong-enough') as string
});

extend('phone', {
  validate: (value: any, dial: any) => {
    const phoneNumber = `+${dial}${value}`;
    const result = phone(phoneNumber, { validateMobilePrefix: false });

    return !!result.phoneNumber;
  },
  message: (_) => i18n.t('errors.invalid-phone') as string
});

extend('maxFileSize', {
  message: (_, values) =>
    i18n.t('commons.validations.max-file-size', { size: values[0] / 1000000 }) as string,
  validate: (value: any, size: any) => {
    return value.size <= size;
  }
});

extend('fileTypes', {
  message: (_, values) =>
    i18n.t('commons.validations.file-type', {
      types: Object.entries(values)
        .filter(([key]) => !Number.isNaN(Number(key)))
        .map(([, value]) => last(value.split('/')))
        .join(', ')
    }) as string,
  validate: (value: any, filesTypes: any) => {
    return filesTypes.includes(value.type);
    // return value.size <= size;
  }
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
