import type { Config } from '../types';

/**
 * Configuration file factory
 */
export function configFactoryWithComputed<T extends Config, C extends Config>(
  config: T,
  computed: (config: Readonly<T>) => Readonly<C>
): Readonly<C & T> {
  return {
    ...config,
    ...computed(config)
  };
}
