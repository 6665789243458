// Import vendors ----------------------------------------------------------------------------------
import { actions, assign, createMachine, sendParent } from 'xstate';
import { Auth } from '@aws-amplify/auth';
// Import utils ------------------------------------------------------------------------------------
import { navigatorLang } from '@/utils/i18n.utils';
// Import types ------------------------------------------------------------------------------------
import type { AnyEventObject } from 'xstate';
// Import utils ------------------------------------------------------------------------------------
import { useAnalytics } from '@/utils/analytics.utils';
// -------------------------------------------------------------------------------------------------

/**
 * Analytics
 */
const { trackSuccess } = useAnalytics();

/**
 * Sign up machine
 */
export const signUpMachine = createMachine(
  {
    id: 'signUp',
    context: {
      credentials: undefined,
      signingUpError: undefined
    },
    initial: 'idle',
    states: {
      idle: {
        entry: assign({
          credentials(_) {
            return undefined;
          }
        }),
        on: {
          SIGN_UP: '#signUp.signingUp'
        }
      },
      signingUp: {
        entry: [
          assign({
            credentials(_, { data }: AnyEventObject) {
              return data;
            }
          }),
          (_, { data }: AnyEventObject) => {
            localStorage.setItem(`consents:${data.username}`, JSON.stringify(data.consents));
          }
        ],
        initial: 'processing',
        states: {
          processing: {
            invoke: {
              src: 'signUp',
              onDone: '#signUp.success',
              onError: {
                actions: assign({
                  signingUpError(_, { data }) {
                    return data;
                  }
                }),
                target: 'failure'
              }
            }
          },
          failure: {
            exit: assign({
              credentials() {
                return undefined;
              },
              signingUpError(_) {
                return undefined;
              }
            }),
            on: {
              SIGN_UP: {
                // HACK : entry isn't triggered in `signingUp` state, so we need to do it here
                actions: assign({
                  credentials(_, { data }: AnyEventObject) {
                    console.log('Set credentials');

                    return data;
                  }
                }),
                target: '#signUp.signingUp'
              },
              RESET: '#signUp.idle'
            }
          }
        }
      },
      success: {
        entry: actions.pure((context: any) => {
          trackSuccess('User SignUp');
          return sendParent({
            type: 'SIGNED_UP',
            data: context.credentials
          });
        }),
        on: {
          RESET: 'idle'
        }
      }
    }
  },
  {
    services: {
      async signUp(_, event: AnyEventObject) {
        return Auth.signUp({
          username: event.data.username,
          password: event.data.password,
          attributes: {
            locale: navigatorLang()
          }
        });
      }
    }
  }
);
