
// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, h, toRefs } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { Library, useIcon } from '@/utils/icon.utils';
// Import type -------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'GlobalIcon',
  props: {
    library: {
      type: String as PropType<Library>,
      default: Library.Regular,
      validator(value: Library) {
        return Object.values(Library).includes(value);
      }
    },
    icon: {
      type: String,
      required: true
      // validator: validateIconName
    },
    size: {
      type: [Number, String],
      default: 16
    },
    color: {
      type: String,
      default: 'primary'
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    rotate: {
      type: [String, Number],
      default: 0
    }
  },
  setup(properties: {
    library: Library;
    icon: string;
    size: number;
    color: string;
    left: boolean;
    right: boolean;
    dense: boolean;
    rotate: string | number;
  }) {
    // Set properties reactive
    const { library, icon, size, color, left, right, dense, rotate } = toRefs(properties);

    const { iconClass } = useIcon(icon, library);

    const hash = computed(() => {
      return btoa(iconClass.value);
    });

    const colorProperty = computed(() => {
      const c = color.value.split(' ');
      const s = new Option().style;
      s.color = c[0];
      return (s.color === c[0] && c.length === 1) || c[0].includes('#')
        ? c[0]
        : `var(--v-${c[0]}-${c[1] ? c[1].replace('-', '') : 'base'})`;
    });

    return () =>
      h(
        'span',
        {
          key: hash.value,
          attrs: { 'data-hash': hash.value },
          style: {
            color: colorProperty.value,
            fontSize: `${size.value}px`,
            marginLeft: left.value ? '-4px' : right.value ? (dense.value ? '4px' : '8px') : 'unset',
            marginRight: left.value ? (dense.value ? '4px' : '8px') : right.value ? '-4px' : 'unset',
            transform: `rotate(${rotate.value}deg)`
          }
        },
        [
          h('i', {
            class: iconClass.value
          })
        ]
      );
  }
});
