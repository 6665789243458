// Import vendors ----------------------------------------------------------------------------------
import { camelCase } from 'lodash';
import { createMachine, sendParent, actions } from 'xstate';
// Import types ------------------------------------------------------------------------------------
import type { AnyEventObject, MachineConfig, MachineOptions } from 'xstate';
import type { Modules } from '../modules';
// Export / declare types --------------------------------------------------------------------------
enum ModuleStateMachineBaseState {
  Disabled = 'disabled',
  Enabled = 'enabled'
}
// -------------------------------------------------------------------------------------------------

/**
 * Simplify module name
 * TODO : improve input typing
 */
export function simplifyName(name: string): keyof Modules {
  return camelCase(name.replace(/Module$/, '')) as keyof Modules;
}

/** Invoke modules machine */
export function invokeModulesMachine(modules: Modules) {
  return Object.values(modules)
    .filter((module) => 'machine' in module)
    .map((module) => module.machine);
}

/** Create module state machine */
export function createModuleStateMachine(
  name: string,
  machine: MachineConfig<any, any, AnyEventObject>,
  options?: Partial<MachineOptions<any, AnyEventObject>>
) {
  return createMachine({
    id: name,
    initial: ModuleStateMachineBaseState.Disabled,
    states: {
      disabled: {
        on: {
          ENABLE: {
            actions() {
              console.log(`🟢 Module "${name}" have been enabled`);
            },
            target: ModuleStateMachineBaseState.Enabled
          }
        }
      },
      enabled: {
        on: {
          DISABLE: {
            actions() {
              console.log(`🔴 Module "${name}" have been disabled`);
            },
            target: ModuleStateMachineBaseState.Disabled
          },
          // Relay module event to core
          '*': {
            actions: actions.pure((_, event: AnyEventObject & { data?: unknown }) =>
              sendParent({ type: `${name}.${event.type}`, data: event.data })
            )
          }
        },
        invoke: createMachine({ id: 'module', ...machine }, options)
      }
    }
  });
}
