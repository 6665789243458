// Import vendors ----------------------------------------------------------------------------------
import Vue from 'vue';
import { abilitiesPlugin, Can } from '@casl/vue';
// Import types ------------------------------------------------------------------------------------
import type { AppAbility } from '@/plugins/podocore/modules/acl/ability';
// -------------------------------------------------------------------------------------------------

export function injectPlugin(appAbility: AppAbility): void {
  // Inject plugin
  Vue.use(abilitiesPlugin, appAbility);
  // Inject component
  Vue.component('Can', Can);
}
