// import { useLocalStorage } from '@vueuse/core';
import { version } from '../../package.json';

import { ref, Ref } from '@vue/composition-api';

// const currentVersion = useLocalStorage('version', version);
const currentVersion = ref(version);

export function useVersion(): {
  currentVersion: Ref<string>;
} {
  return {
    currentVersion
  };
}
