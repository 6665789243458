// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
import { actions, assign, sendParent } from 'xstate';
import { AbilityBuilder } from '@casl/ability';
import { ref } from '@vue/composition-api';
// Import plugin -----------------------------------------------------------------------------------
import { injectPlugin } from './plugin';
// Import factories --------------------------------------------------------------------------------
import { ModuleWithStateFactory } from '@/plugins/podocore/factories/ModuleWithState.factory';
// Import helpers ----------------------------------------------------------------------------------
import { createModuleStateMachine } from '@/plugins/podocore/helpers/modules.helpers';
// Import extender ---------------------------------------------------------------------------------
import { extendRawAbility } from './extender';
// Import ability ----------------------------------------------------------------------------------
import { AppAbility } from './ability';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import types ------------------------------------------------------------------------------------
import type { DoneInvokeEvent } from 'xstate';
import type { RawRuleOf, AnyAbility, SubjectType } from '@casl/ability';
// -------------------------------------------------------------------------------------------------

interface IResponse {
  abilities: RawRuleOf<AppAbility>[];
}

/**
 * Acl module
 */
@injectable()
export class AclModule extends ModuleWithStateFactory {
  // TODO : Find type
  private readonly _ability: AbilityBuilder<AppAbility>;
  private readonly _build: AppAbility;
  private readonly _reactiveBuild: AnyAbility;

  constructor() {
    super();

    const unsubscribeSet = new Set<() => void>();

    this._ability = new AbilityBuilder(AppAbility);
    this._build = this._ability.build();
    this._reactiveBuild = this._reactiveAbility(this._build);

    injectPlugin(this._build);

    this._machine = createModuleStateMachine(
      this._name,
      {
        initial: 'init',
        context: {
          isInitialized: false,
          remainsBeforeFatal: 6,
          features: undefined,
          error: undefined
        },
        exit: () => {
          unsubscribeSet.forEach((unsubscribe) => unsubscribe());
          unsubscribeSet.clear();
        },
        states: {
          init: {
            always: {
              actions: () => {
                const bus = this._core.getModule('bus');

                unsubscribeSet.add(
                  bus.subscribe(bus.events.workspaceChanged, () => {
                    if (this.service.state.matches('fetching')) {
                      this.service.send({
                        type: 'CANCEL'
                      });
                    }
                    this.service.send({
                      type: 'CHECK'
                    });
                  })
                );
              },
              target: 'fetching'
            }
          },
          fetching: {
            on: {
              CANCEL: 'failure'
            },
            invoke: {
              src: 'fetchAbility',
              onDone: {
                actions: [
                  (_, event: DoneInvokeEvent<IResponse>) => {
                    // Extend raw ability
                    const extendedRawAbility = extendRawAbility(event.data.abilities);

                    // Update ability
                    this._build.update(extendedRawAbility);
                  },
                  assign({
                    features(_, event) {
                      return event.data.features;
                    }
                  })
                ],
                target: 'success'
              },
              onError: {
                actions: assign({
                  error(_, event) {
                    return event.data;
                  },
                  remainsBeforeFatal(context) {
                    return context.remainsBeforeFatal - 1;
                  }
                }),
                target: 'failure'
              }
            }
          },
          success: {
            entry: [
              function () {
                console.log('👮 ACL updated');
              },
              actions.pure(() => sendParent({ type: 'PROVISIONED' })),
              assign({
                isInitialized(context) {
                  if (!context.isInitialized) {
                    return true;
                  }
                }
              })
            ],
            on: {
              CHECK: 'fetching'
            }
          },
          failure: {
            entry: actions.pure((context) => {
              if (context.remainsBeforeFatal <= 0) {
                console.log(`🛑 Module "${this._name}" cannot be initialized`);
                return sendParent({ type: 'FATAL', data: context.error });
              }
            }),
            exit: assign({
              error() {
                return undefined;
              }
            }),
            after: {
              // Retry only if not initialized / no remains trial
              5000: {
                cond(context) {
                  return !context.isInitialized && context.remainsBeforeFatal > 0;
                },
                target: 'fetching'
              }
            },
            on: {
              CHECK: 'fetching'
            }
          }
        }
      },
      {
        services: {
          fetchAbility: async (): Promise<IResponse> => {
            let response;

            if (this._core.getModuleService('doctor', true).state.value === 'enabled') {
              response = await this._core
                .getModule('request')
                .authenticatedRequest<IResponse>(`${apiConfig.default}/abilities/`)
                .toPromise();
            } else {
              response = await this._core
                .getModule('request')
                .request<IResponse>(`${apiConfig.default}/abilities/`)
                .toPromise();
            }

            return response.data;
          }
        }
      }
    );
  }

  public get ability(): AbilityBuilder<AppAbility> {
    return this._ability;
  }

  public get build(): AppAbility {
    return this._build;
  }

  public get reactiveBuild(): AnyAbility {
    return this._reactiveBuild;
  }

  private _reactiveAbility(ability: AnyAbility): AnyAbility {
    if (Object.hasOwnProperty.call(ability, 'possibleRulesFor')) {
      return ability;
    }

    const watcher = ref(true);
    ability.on('updated', () => {
      watcher.value = !watcher.value;
    });

    const possibleRulesFor = ability.possibleRulesFor.bind(ability);
    ability.possibleRulesFor = (action: string, subject: SubjectType) => {
      watcher.value = watcher.value; // eslint-disable-line
      return possibleRulesFor(action, subject);
    };
    ability.can = ability.can.bind(ability);
    ability.cannot = ability.cannot.bind(ability);

    return ability;
  }
}
